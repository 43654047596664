import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector } from "react-redux";
import { ReusableSelect } from "../muiResuableComponents";

import OPTIONS from "../../../constant/Option";
import ProfileService from "../../Profile/profileService";
const FamilyInfoCard = () => {
  const [isEditable, setIsEditable] = useState(false);
  const familyInfo = useSelector((state) => state.myProfile?.familyDetailinfo);

  useEffect(() => {
    console.log("family Info", familyInfo);
    setDetails(familyInfo);
  }, [familyInfo]);

  const [details, setDetails] = useState({});

  const handleEditToggle = () => {
    setIsEditable(!isEditable);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };


 async function updateDetails(){

 const data=await ProfileService.updateMyFamilyInfo(details,null)
}



  return (
    <Card
      sx={{
        maxWidth: 900,
        width: { xs: "95%" },
        mx: "auto",
        mt: 3,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Family Information
          </Typography>
          <IconButton onClick={handleEditToggle} color="primary">
            {isEditable ? <span onClick={e=>updateDetails()}><SaveIcon/></span> : <EditIcon />}
          </IconButton>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                // <TextField
                //   fullWidth
                //   label="Family Type"
                //   variant="outlined"
                //   name="familyType"
                //   value={details?.familyType || ''}
                //   onChange={handleChange}
                //   sx={{ mb: 1 }}
                // />

                <ReusableSelect
                  label="Family Type"
                  name="familyType"
                  value={details?.familyType}
                  options={OPTIONS?.familyTypeOptions?.getAllOptions()}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Family Type: {details?.familyType}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                // <TextField
                //   fullWidth
                //   label="Native State"
                //   variant="outlined"
                //   name="nativeState"
                //   value={details?.nativeState || ''}
                //   onChange={handleChange}
                //   sx={{ mb: 1 }}
                // />

                <ReusableSelect
                  label="Native State"
                  name="nativeState"
                  value={details?.nativeState}
                  options={OPTIONS?.state.getAllOptions()}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Native Place: {details?.nativeCity}, {details?.nativeState}
                </Typography>
              )}
            </Grid>

            {isEditable ? (
              <Grid container alignItems="center" sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Native City"
                  variant="outlined"
                  name="nativeCity"
                  value={details?.nativeCity || ""}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              </Grid>
            ) : null}

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                // <TextField
                //   fullWidth
                //   label="Father's Occupation"
                //   variant="outlined"
                //   name="fatherOccupation"
                //   value={details?.fatherOccupation || ''}
                //   onChange={handleChange}
                //   sx={{ mb: 1 }}
                // />

                <ReusableSelect
                  label="Father Occupation"
                  name="fatherOccupation"
                  value={details?.fatherOccupation}
                  options={OPTIONS?.fatherOccupation.getAllOptions()}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Father's Occupation: {details?.fatherOccupation}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <TextField
                  fullWidth
                  label="Father's Name"
                  variant="outlined"
                  name="fatherName"
                  value={details?.fatherName || ""}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : (
                <Typography variant="body1">
                  Father's Name: {details?.fatherName}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? (
                <TextField
                  fullWidth
                  type="number"
                  label="Married Sister"
                  variant="outlined"
                  name="numberOfMarriedsister"
                  value={details?.numberOfMarriedsister}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                // <TextField
                //   fullWidth
                //   label="Mother's Occupation"
                //   variant="outlined"
                //   name="motherOccupation"
                //   value={details?.motherOccupation || ''}
                //   onChange={handleChange}
                //   sx={{ mb: 1 }}
                // />

                <ReusableSelect
                  label="Mother Occupation"
                  name="motherOccupation"
                  value={details?.motherOccupation}
                  options={OPTIONS?.motherOcupation.getAllOptions()}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Mother's Occupation: {details?.motherOccupation}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                // <TextField
                //   fullWidth
                //   label="Family Status"
                //   variant="outlined"
                //   name="familyStatus"
                //   value={details?.familyStatus || ''}
                //   onChange={handleChange}
                //   sx={{ mb: 1 }}
                // />

                <ReusableSelect
                  label="Family Status"
                  name="familyStatus"
                  value={details?.familyStatus}
                  options={OPTIONS?.familyStatusOptions.getAllOptions()}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Family Status: {details?.familyStatus}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <TextField
                  fullWidth
                  label="Brothers"
                  variant="outlined"
                  type="number"
                  name="numberOfBrother"
                  value={details?.numberOfBrother || ""}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : (
                <Typography variant="body1">
                  No of Brothers: {details?.numberOfBrother} (
                  {details?.numberOfMarriedBrother} Married)
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <TextField
                  fullWidth
                  label="Sisters"
                  variant="outlined"
                  name="numberOfSister"
                  type="number"
                  value={details?.numberOfSister || ""}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : (
                <Typography variant="body1">
                  No of Sisters: {details?.numberOfSister} (
                  {details?.numberOfMarriedsister} Married)
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? (
                <TextField
                  fullWidth
                  label="About Family"
                  variant="outlined"
                  name="aboutFamily"
                  value={details?.aboutFamily || ""}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : (
                <Typography variant="body1">
                  {isEditable ? null : (
                    <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
                  )}
                  About Family: {details?.aboutFamily}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? (
                <TextField
                  fullWidth
                  label="Married Brother"
                  type="number"
                  variant="outlined"
                  name="numberOfMarriedBrother"
                  value={details?.numberOfMarriedBrother}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FamilyInfoCard;
