import React from 'react';
import { Box, TextField, Button, Typography, Link } from '@mui/material';

function ResetPasswordCard() {
  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        mx: 'auto',
        mt: 5,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      
      <Typography variant="h5" sx={{ mb: 1 }}>
        Reset Password
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: 'gray' }}>
        Please choose your new password
      </Typography>
      <TextField
        label="Old Password"
        type="password"
        variant="outlined"
        fullWidth
      />
      <TextField
        label="New Password"
        type="password"
        variant="outlined"
        fullWidth
      />
      <TextField
        label="Confirm Password"
        type="password"
        variant="outlined"
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          mt: 2,
          backgroundColor: '#00796b',
          ':hover': {
            backgroundColor: '#004d40',
          },
        }}
      >
        Save New Password
      </Button>
    </Box>
  );
}

export default ResetPasswordCard;
