import React, { useState } from 'react';
import { Card, CardContent, Grid, IconButton, Typography, TextField, FormControl, FormLabel, FormGroup, FormHelperText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CircleIcon from '@mui/icons-material/Circle';
import { useSelector } from 'react-redux';

const PersonalDetailsCard = () => {
  const [isEditable, setIsEditable] = useState(false);
  const basicInfo = useSelector(state => state.myProfile.BasicInfo);
  const [details, setDetails] = useState({
    name:"mohd shakir ansari",
    age:24,
    gender:"Male",
    height: '5ft 4in',
    motherTongue: 'Hindi / Urdu',
    caste: 'Sunni / Ansari',
    disability: 'No',
    maritalStatus:"Never Married",
    ...basicInfo
  });

  const handleEditToggle = () => {
    setIsEditable(!isEditable);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  console.log("Basic info",basicInfo)
  useState(()=>{
    console.log("Basic info",basicInfo)
  },[basicInfo])

  return (
    <Card sx={{maxWidth: 900,width:{xs:"95%"}, mx: 'auto', mt: 3, p: 3, borderRadius: 2, boxShadow: 3 }}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Personal Details</Typography>
          <IconButton onClick={handleEditToggle} color="primary">
            {isEditable ? <SaveIcon /> : <EditIcon />}
          </IconButton>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>

          <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Name"
                  name="name"
                  value={details.name}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Name: {details.name}</Typography>
              )}
            </Grid>


            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Age"
                  name="age"
                  value={details.age}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Age: {details.age}</Typography>
              )}
            </Grid>



            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Gender"
                  name="gender"
                  value={details.gender}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1"><FormControl component="fieldset">
                
                </FormControl>Gender:{details.gender}</Typography>
              )}
            </Grid>

            



            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Height"
                  name="height"
                  value={details.height}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Height: {details.height}</Typography>
              )}
            </Grid>
            
          </Grid>

          <Grid item xs={12} sm={6}>

          <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="maritalStatus"
                  name="maritalStatus"
                  value={details.maritalStatus}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Marital Status: {details.maritalStatus}</Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  label="Caste"
                  name="caste"
                  value={details.caste}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Caste: {details.caste}</Typography>
              )}
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  label="Any Disability"
                  name="disability"
                  value={details.disability}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Any Disability: {details.disability}</Typography>
              )}
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable?null:<CircleIcon sx={{ color: 'red', fontSize: 8, mr: 1 }} />}
              {isEditable ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  label="Mother Tongue"
                  name="motherTongue"
                  value={details.motherTongue}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">Mother Tongue: {details.motherTongue}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PersonalDetailsCard;
