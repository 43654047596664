import React, { useEffect, useState } from "react";
import axios from "axios"
import {
  Typography,
  Switch,
  Grid,
  Paper,
  FormControlLabel,
  Card,
} from "@mui/material";
import { Box, TextField, Button, Link } from "@mui/material";
import { Avatar, Badge } from "@mui/material";
import UserProfileService from "../../services/Profile.services";

const UploadAndAccess = () => {

  const [profileImage,setProfileImage]=useState(null);
  const [image1,setImage1]=useState(null);
  const [image2,setImage2]=useState(null);
  const [image3,setImage3]=useState(null);


 async function uploadImage(){

   let newFormData=new FormData()
   newFormData.append("profileImage",profileImage)
   newFormData.append("image1",image1)
   newFormData.append("image2",image2)
   newFormData.append("image3",image3)

const data= await UserProfileService.uploadImage(newFormData)
   
  }


  useEffect(()=>{
console.log("profile image change",profileImage)
  },profileImage)

  return (
    <Box  sx={{height:"100%",overflowY:'auto'}}>
      <Card
        sx={{
          maxWidth: 900,
          width: { xs: "95%" },
          mx: "auto",
          mt: 3,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" sx={{ mb: 1 }}>
        Upload  Images
        </Typography>

        <Grid container spacing={2}>
        <Grid item lg={3} sm={6} xs={6}>
            <CircularImageCard title={"Profile"} selectedImage={profileImage} setSelectedImage={setProfileImage}/>
          </Grid>
          <Grid item lg={3} sm={6} xs={6}>
            <CircularImageCard title={"image 1"} selectedImage={image1} setSelectedImage={setImage1}/>
          </Grid>
          <Grid item lg={3} sm={6} xs={6}>
            <CircularImageCard title={"Image 2"} selectedImage={image2} setSelectedImage={setImage2}/>
          </Grid>
          <Grid item lg={3} sm={6} xs={6}>
            <CircularImageCard title={"image 3"}selectedImage={image3} setSelectedImage={setImage3} />
          </Grid>
        
        </Grid>
        <Grid container md={12} sx={{marginTop:5,display:"flex", justifyContent:"center"}}>
          <Grid item={2}>
            <Button variant="contained" color="success" onClick={uploadImage}>upload</Button>
          </Grid>
        </Grid>
      </Card>

      <Paper
        sx={{
          maxWidth: 900,
          width: { xs: "95%" },
          mx: "auto",
          mt: 3,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" sx={{ mb: 1 }}>
          Gallery & Profile Picture Access
        </Typography>

        <NotificationSwitch
          title="All Members"
          description="Your Gallery will be Accessable To Every One"
          defaultChecked={true}
        />
        <NotificationSwitch
          title="Premium Members"
          description="Your Gallery will be Accessable To Premium Members"
          defaultChecked={false}
        />
        <NotificationSwitch
          title="Ask Me First"
          description="Your profile and gallery are accessible only when you allow user"
          defaultChecked={true}
        />

        <Button
          variant="contained"
          color="success"
          fullWidth
          sx={{
            mt: 2,
          
          
          }}
        >
          Save Notification Settings
        </Button>
      </Paper>
    </Box>
  );
};

export default UploadAndAccess;

function NotificationSwitch({ title, description, defaultChecked }) {
  const [checked, setChecked] = useState(defaultChecked);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        borderBottom: "1px solid #e0e0e0",

        
      }}
    >
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </Box>
      <Switch
        checked={checked}
        onChange={() => setChecked(!checked)}
        color="primary"
      />
    </Box>
  );
}

// import React, { useState } from 'react';
// import { Box, Avatar, Typography } from '@mui/material';

function CircularImageCard({ title,selectedImage,setSelectedImage }) {
  // Local state for each instance
const [preview,setPreview]=useState(null)

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreview(imageUrl)
      setSelectedImage(file);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 2,
      
      }}
    >
      <input
        accept="image/*"
        type="file"
        id={`upload-button-${title}`} // Unique ID for each input
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <label htmlFor={`upload-button-${title}` }>
        <Avatar
          src={preview || "https://tse4.mm.bing.net/th?id=OIP.ed0RqUUqkqGszO7DikXhagAAAA&pid=Api&P=0&h=180"}
          alt="Upload Preview"
          sx={{
            width: "80%",  // Width is 80% of the parent container
    height: "auto",  // Maintain aspect ratio
    aspectRatio: "1",  // Ensures the avatar remains square
    borderRadius: "50%",  // Circle shape
    cursor: "pointer",
    maxWidth: "150px",  // Ensure max width doesn't exceed this value
    maxHeight: "150px",  // Ensure max height doesn't exceed this value
   border:"2px solid gray",
    marginLeft:"auto",
    marginRight:"auto",
  
    "&:hover": {
      opacity: 0.8,
    },
          }}
        />
      </label>
      <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
        {title}
      </Typography>
    </Box>
  );
}
