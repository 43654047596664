import React, { useEffect, useState } from "react";
import PersonalDetailsCard from "./cards/personalCard";
import EducationCareerCard from "./cards/educationAndCarrierCard";
import FamilyInfoCard from "./cards/familyCards";
import PartnerPreferencesCard from "./cards/partnerPreferenceCard";
import { Box } from "@mui/material";
import UserProfileService from "../../services/Profile.services";
import { useParams } from "react-router-dom";

const Profile = () => {
  const [profile, setProfile] = useState({});

  const { id } = useParams();

  async function fetchuserProfile() {
    const data = await UserProfileService.getOtherProfileById({}, id);
    console.log("****profile DATATATA****", data.data);
    setProfile(data.data);
  }

  useEffect(() => {
    fetchuserProfile();
  }, []);
  return (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
     
      <PersonalDetailsCard data={profile.BasicInfo} otherData={profile} />

      <EducationCareerCard data={profile.EducationAndCarrierInfo} />

      <FamilyInfoCard data={profile.familyDetailinfo} />

      <PartnerPreferencesCard data={profile.partnerPreferenceInfo} />
    </Box>
  );
};

export default Profile;
