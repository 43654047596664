
import { Outlet } from 'react-router-dom'
import React from 'react'

const OtherActivity = () => {
  return (
    <Outlet/>
  )
}

export default OtherActivity