// components/DynamicForm.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { closeForm, setFormData } from '../redux/formSlice';
import { closeForm,setFormData } from '../redux/astroSlice';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, colors,Grid  } from '@mui/material';

const DynamicForm = () => {
  const dispatch = useDispatch();
  const { isOpen, fields, formData, onSubmit,formInfo } = useSelector((state) => state.astro.Form);

  const handleInputChange = (field, value) => {
    if(field=='vehicleNumber'){
      value=value.toUpperCase()
    }
    dispatch(setFormData({ field, value }));
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit(formData);
    dispatch(closeForm());
  };

  const handleCancel = () => {
    dispatch(closeForm());
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>{formInfo.title || "Fill Details"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={fields.length > 2 ? 6 : 12} key={field.name}>
              <TextField
                label={field.label}
                type={field.type || 'text'}
                value={formData[field.name] || ''}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant='outlined'
          sx={{
            color: "gray",
            borderColor: 'gray',
            '&:hover': {
              borderColor: 'gray',
              backgroundColor: 'transparent',
            },
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant='outlined'>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicForm;
