import React from "react";
import { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Box,
  Button,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FlagIcon from "@mui/icons-material/Flag";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import UserProfileService from "../../services/Profile.services";
import { useNavigate } from "react-router-dom";

function BrideCard({ data }) {
  const navigate = useNavigate();
  async function shortList(id) {
    const data = await UserProfileService.shortlist({}, id);
  }

  async function expressInterest(id) {
    const data = await UserProfileService.expressInterest({}, id);
  }
  async function expressInterest(id) {
    const data = await UserProfileService.expressInterest({}, id);
  }

  async function handleIgnore(id) {
    const data = await UserProfileService.ignoreProfile({}, id);
  }

  function handleProfileView() {
    navigate(`/dashboard/profile/${data._id}`);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Handle menu open and close
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Action functions
  async function handleIgnore(id) {
    await UserProfileService.ignoreProfile({}, id);
    handleMenuClose(); // Close menu after action
  }

  async function handleReport(id) {
    await UserProfileService.reportProfile({}, id);
    handleMenuClose(); // Close menu after action
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: 2,
        maxWidth: "100%",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "25%",
            height: "auto",
            minHeight: "100px",
            maxHeight: "100px",
            borderRadius: 2,
            border: "1px solid gray",
            marginTop: 1,
            marginLeft: 1,
            position: "relative", // Allows positioning of the child elements
            overflow: "hidden", // Ensures content stays within the box boundaries
          }}
        >
          {/* Blurred Background */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${
                data.profileImage
                  ? data.profileImage
                  : "https://image.tensorartassets.com/cdn-cgi/image/anim=false,plain=false,w=320,q=85/posts/images/726175457019670144/612bae61-ca53-4224-8b98-c00db8c3ba16.jpg"
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(8px)", // Blur effect for the background
              zIndex: 1, // Lower z-index so it's behind the image
            }}
          />

          {/* Main Image */}
          <Box
            component="img"
            onClick={(e) => handleProfileView()}
            src={
              data.profileImage
                ? data.profileImage
                : "https://image.tensorartassets.com/cdn-cgi/image/anim=false,plain=false,w=320,q=85/posts/images/726175457019670144/612bae61-ca53-4224-8b98-c00db8c3ba16.jpg"
            }
            alt="Profile"
            sx={{
              width: "100%", // Full width of the container
              height: "100%", // Full height of the container
              objectFit: "contain", // Maintain aspect ratio and ensure the image fits within the box
              borderRadius: 2,
              border: "1px solid transparent",
              position: "relative", // Position above the background
              zIndex: 2, // Higher z-index so it's above the background
            }}
          />
        </Box>

        <Box sx={{ flex: 1, p: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {data.firstName + " " + data.lastName}
            </Typography>
            {/* More Options Button */}
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation(); // Prevent click propagation
                handleMenuClick(event);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            {/* Menu Component */}
            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleIgnore(data._id);
                }}
              >
                Ignore
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  handleReport(data._id);
                }}
              >
                Report
              </MenuItem>
            </Menu>
          </Stack>

          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
            {data.age} | {data.height} | {data.maritalStatus}
          </Typography>

          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <FlagIcon fontSize="small" color="action" />
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {data.religion}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <FlagIcon fontSize="small" color="action" />
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {data.higestEducation}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <LocationOnIcon fontSize="small" color="action" />
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {data.state + "  " + data.city}
            </Typography>
          </Stack>
        </Box>
      </Box>

      <Box sx={{ width: "100%", my: 0.8 }}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ mt: 2, justifyContent: "space-evenly", padding: 0 }}
        >
          <Button
            variant="outlined"
            color="success"
            sx={{ px: 1, width: { sx: "40%", md: "30%" } }}
            onClick={(e) => shortList(data._id)}
          >
            Shortlist
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ px: 1, width: { sx: "40%", md: "30%" } }}
            onClick={(e) => expressInterest(data._id)}
          >
            Express Interest
          </Button>
        </Stack>
      </Box>
    </Card>
  );
}

export default BrideCard;
