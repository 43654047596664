import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MessageSidebar from '../components/profileCard/messageSideBar';
import ChatApp from '../components/profileCard/RightMessage';
import {  useMediaQuery, useTheme } from '@mui/material';
import UserProfileService from '../../services/Profile.services';
const Message = () => {
  const [isSideBar, setIsSideBar] = useState(true);
  const [recentChat,setRecentChat]=useState([])
  const theme = useTheme();  // Access theme for breakpoints
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));




 async function RecentChat(){
  const data= await UserProfileService.getAllUser();
  console.log("Recent Chat",data)
  setRecentChat(data.data)

  console.log("Recent Chat",recentChat)
 }
  useEffect(()=>{
RecentChat()
  },[])

  return (
    <Box sx={{ width: "100%", height: "100%",  display: 'flex',overflow:"hidden" }}>
      {/* Sidebar */}
      <Box
        sx={{
          width:isMobile ? (isSideBar ? '100%' : '0%') : (isSideBar ? '30%' : '0%'),          transition: "width 0.3s ease",
         
          overflow: "hidden",
        
        }}
      >
        {isSideBar && <MessageSidebar  isSideBar={isSideBar} setIsSideBar={setIsSideBar} recentChat={recentChat} />}
      </Box>

      {/* Chat Box */}
      <Box
        sx={{
          flexGrow: 1,
          transition: "margin-left 0.3s ease",
          width: isSideBar ? isMobile?'0.001%':'75%' : '10%',
        }}
      >
        <ChatApp isSideBar={isSideBar} setIsSideBar={setIsSideBar} recentChat={recentChat}/>
      </Box>
    </Box>
  );
};

export default Message;
