import { createSlice } from '@reduxjs/toolkit';

// Initial state as an empty object
const initialState = {};

const myProfileSlice = createSlice({
    name: 'myProfile',
    initialState,
    reducers: {
        // Action to set the entire user profile data
        setUserProfile: (state, action) => {
            return { ...action.payload }; // Replace the state with the new data
        },
        // Action to update a specific part of the user profile
        updateUserProfile: (state, action) => {
            const { section, newData } = action.payload;
            if (state[section]) {
                state[section] = {
                    ...state[section],
                    ...newData
                };
            } else {
                // Create the section if it doesn't exist
                state[section] = newData;
            }
        }
    }
});

// Exporting actions
export const { setUserProfile, updateUserProfile } = myProfileSlice.actions;

// Exporting the reducer
export default myProfileSlice.reducer;
