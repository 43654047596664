import React, { useState } from 'react';
import { Box, Typography, Switch, Paper, FormControlLabel } from '@mui/material';

function NotificationSwitch({ title, description, defaultChecked }) {
  const [checked, setChecked] = useState(defaultChecked);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{title}</Typography>
        <Typography variant="body2" color="textSecondary">{description}</Typography>
      </Box>
      <Switch
        checked={checked}
        onChange={() => setChecked(!checked)}
        color="primary"
      />
    </Box>
  );
}

function NotificationSettingsCard() {
  return (
    <Paper
      sx={{
        maxWidth: 900,
        mx: 'auto',
        mt: 4,
        p: 1,
        borderRadius: 2,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <NotificationSwitch
        title="Order Notification"
        description="You will be notified when customer order any product"
        defaultChecked={true}
      />
      <NotificationSwitch
        title="Order Status Changed"
        description="You will be notified when customer makes changes to the order"
        defaultChecked={false}
      />
      <NotificationSwitch
        title="Order Delivered"
        description="You will be notified once the order is delivered"
        defaultChecked={true}
      />
      <NotificationSwitch
        title="Email Notification"
        description="Turn on email notifications to get updates through email"
        defaultChecked={true}
      />
    </Paper>
  );
}

export default NotificationSettingsCard;
