import React from 'react';
import { Box, Typography, Table,Card, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@mui/material';

function PackageTable({ title, rows }) {
  return (
    <Box sx={{ flex: 1, mb: { xs: 2, md: 0 } }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>{title}</Typography>
      <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #ddd' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Arial' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontFamily: 'Arial' }}>Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontFamily: 'Roboto', color: '#555' }}>{row.label}</TableCell>
                <TableCell sx={{ fontFamily: 'Roboto', color: '#555' }}>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function MyPackageCard() {
  const currentPackage = [
    { label: 'User Package', value: 'Default' },
    { label: 'Package Price', value: '---' },
    { label: 'Package Validity', value: '---' },
    { label: 'Package Gateway', value: '----' }
  ];

  const remainingPackage = [
    { label: 'Interests Express', value: 'Unlimited' },
    { label: 'Photo Request', value: 'Limited' },
    { label: 'Remaining Messages', value: 'Nil' },
    { label: 'Remaining Contact View', value: 'Nil' }
  ];

  return (
    <Card sx={{ maxWidth: 900, mx: 'auto', mt: 3, p: 2, borderRadius: 2, boxShadow: 3 }}>
    
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>My Package</Typography>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
        <PackageTable title="YOUR CURRENT PACKAGE" rows={currentPackage} />
        <PackageTable title="REMAINING PACKAGE" rows={remainingPackage} />
      </Box>

      <Button
        variant="contained"
        color='success'
        sx={{
          mt: 3,
          // bgcolor: 'green',
          // color: 'white',
          fontWeight: 'bold',
          width: '100%',
          ':hover': {
            bgcolor: 'darkgreen'
          }
        }}
      >
        Buy Premium Plans
      </Button>
      </Card>
  );
}

export default MyPackageCard;
