const religionOptions = [
  { key: 'christianity', label: 'Christianity' },
  { key: 'hindu', label: 'Hindu' },
  { key: 'islam', label: 'Islam' },
  { key: 'sikh', label: 'Sikh' },
  { key: 'nonreligious', label: 'Nonreligious' },
  { key: 'bhori', label: 'Bhori' },
  { key: 'buddhism', label: 'Buddhism' },
  { key: 'christianity_anglican', label: 'Christianity - Anglican' },
  { key: 'christianity_baptist', label: 'Christianity - Baptist' },
  { key: 'christianity_born_again', label: 'Christianity - Born Again' },
  { key: 'christianity_catholic', label: 'Christianity - Catholic' },
  {
    key: 'christianity_catholic_kananaya',
    label: 'Christianity - Catholic Kananaya',
  },
  {
    key: 'christianity_catholic_latin',
    label: 'Christianity - Catholic Latin',
  },
  {
    key: 'christianity_catholic_malankara',
    label: 'Christianity - Catholic Malankara',
  },
  {
    key: 'christianity_catholic_roman',
    label: 'Christianity - Catholic Roman',
  },
  {
    key: 'christianity_catholic_syrian',
    label: 'Christianity - Catholic Syrian',
  },
  { key: 'christianity_cms', label: 'Christianity - CMS' },
  { key: 'christianity_csi', label: 'Christianity - CSI' },
  { key: 'christianity_evangelical', label: 'Christianity - Evangelical' },
  { key: 'christianity_jacobite', label: 'Christianity - Jacobite' },
  { key: 'christianity_malayalam', label: 'Christianity - Malayalam' },
  { key: 'christianity_marthomite', label: 'Christianity - Marthomite' },
  { key: 'christianity_nadar', label: 'Christianity - Nadar' },
  { key: 'christianity_orthodox', label: 'Christianity - Orthodox' },
  { key: 'christianity_pentecost', label: 'Christianity - Pentecost' },
  { key: 'christianity_protestant', label: 'Christianity - Protestant' },
  { key: 'christianity_thai', label: 'Christianity - Thai' },
  { key: 'christianity_tibetan', label: 'Christianity - Tibetan' },
  { key: 'dawoodi_bhora', label: 'Dawoodi Bhora' },
  { key: 'hindu_ganiga', label: 'Hindu - Ganiga' },
  { key: 'hindu_96k_kokanastha', label: 'Hindu - 96K Kokanastha' },
  { key: 'hindu_adi_dravida', label: 'Hindu - Adi Dravida' },
  { key: 'hindu_agamudayaar', label: 'Hindu - Agamudayaar' },
  { key: 'hindu_agarwal', label: 'Hindu - Agarwal' },
  { key: 'hindu_arora', label: 'Hindu - Arora' },
  { key: 'hindu_arya_vysya', label: 'Hindu - Arya Vysya' },
  { key: 'hindu_balija_naidu', label: 'Hindu - Balija Naidu' },
  { key: 'hindu_bania', label: 'Hindu - Bania' },
  { key: 'hindu_banjara', label: 'Hindu - Banjara' },
  { key: 'hindu_bengali', label: 'Hindu - Bengali' },
  { key: 'hindu_bhandari', label: 'Hindu - Bhandari' },
  { key: 'hindu_billava', label: 'Hindu - Billava' },
  { key: 'hindu_brahmin', label: 'Hindu - Brahmin' },
  { key: 'hindu_brahmin_anavil', label: 'Hindu - Brahmin Anavil' },
  { key: 'hindu_brahmin_bengali', label: 'Hindu - Brahmin Bengali' },
  { key: 'hindu_brahmin_davadnya', label: 'Hindu - Brahmin Davadnya' },
  { key: 'hindu_brahmin_deshastha', label: 'Hindu - Brahmin Deshastha' },
  { key: 'hindu_brahmin_garhwali', label: 'Hindu - Brahmin Garhwali' },
  { key: 'hindu_brahmin_gaur', label: 'Hindu - Brahmin Gaur' },
  {
    key: 'hindu_brahmin_gowd_saraswat',
    label: 'Hindu - Brahmin Gowd Saraswat',
  },
  { key: 'hindu_brahmin_gujarati', label: 'Hindu - Brahmin Gujarati' },
  { key: 'hindu_brahmin_havyaka', label: 'Hindu - Brahmin Havyaka' },
  {
    key: 'hindu_brahmin_kannada_madhva',
    label: 'Hindu - Brahmin Kannada Madhva',
  },
  { key: 'hindu_brahmin_kanyakubja', label: 'Hindu - Brahmin Kanyakubja' },
  { key: 'hindu_brahmin_kokanastha', label: 'Hindu - Brahmin Kokanastha' },
  { key: 'hindu_brahmin_kumoani', label: 'Hindu - Brahmin Kumaoni' },
  {
    key: 'hindu_brahmin_maharashtrian',
    label: 'Hindu - Brahmin Maharashtrian',
  },
  { key: 'hindu_brahmin_maithil', label: 'Hindu - Brahmin Maithil' },
  { key: 'hindu_brahmin_nagar', label: 'Hindu - Brahmin Nagar' },
  { key: 'hindu_brahmin_pareek', label: 'Hindu - Brahmin Pareek' },
  { key: 'hindu_brahmin_punjabi', label: 'Hindu - Brahmin Punjabi' },
  { key: 'hindu_brahmin_saryuparin', label: 'Hindu - Brahmin Saryuparin' },
  { key: 'hindu_brahmin_smartha', label: 'Hindu - Brahmin Smartha' },
  { key: 'hindu_brahmin_telugu', label: 'Hindu - Brahmin Telugu' },
  { key: 'hindu_bramhan_bhumihar', label: 'Hindu - Bramhan Bhumihar' },
  { key: 'hindu_bunt', label: 'Hindu - Bunt' },
  { key: 'hindu_byahut_kalwa', label: 'Hindu - Byahut Kalwa' },
  { key: 'hindu_chandraseniya_ckp', label: 'Hindu - Chandraseniya(CKP)' },
  { key: 'hindu_chettiar', label: 'Hindu - Chettiar' },
  { key: 'hindu_coorgi', label: 'Hindu - Coorgi' },
  { key: 'hindu_daraji', label: 'Hindu - Daraji' },
  { key: 'hindu_devadiga', label: 'Hindu - Devadiga' },
  { key: 'hindu_devanga', label: 'Hindu - Devanga' },
  { key: 'hindu_dheevara', label: 'Hindu - Dheevara' },
  { key: 'hindu_dhobi', label: 'Hindu - Dhobi' },
  { key: 'hindu_digambar', label: 'Hindu - Digambar' },
  { key: 'hindu_ezhava', label: 'Hindu - Ezhava' },
  { key: 'hindu_ezhuthassan', label: 'Hindu - Ezhuthassan' },
  { key: 'hindu_gold_smith', label: 'Hindu - Gold Smith' },
  { key: 'hindu_goswami', label: 'Hindu - Goswami' },
  { key: 'hindu_gounder', label: 'Hindu - Gounder' },
  { key: 'hindu_gowda', label: 'Hindu - Gowda' },
  { key: 'hindu_gujarati', label: 'Hindu - Gujarati' },
  { key: 'hindu_gupta', label: 'Hindu - Gupta' },
  { key: 'hindu_nair_veluthedath', label: 'Hindu - Hindu Nair Veluthedath' },
  { key: 'hindu_iyengar', label: 'Hindu - Iyengar' },
  { key: 'hindu_iyer', label: 'Hindu - Iyer' },
  { key: 'hindu_jat', label: 'Hindu - Jat' },
  { key: 'hindu_jain', label: 'Hindu - Jain' },
  { key: 'hindu_jatav', label: 'Hindu - Jatav' },
  { key: 'hindu_kachara', label: 'Hindu - Kachara' },
  { key: 'hindu_kadava', label: 'Hindu - Kadava' },
  { key: 'hindu_kannada', label: 'Hindu - Kannada' },
  { key: 'hindu_kapoor', label: 'Hindu - Kapoor' },
  { key: 'hindu_kataria', label: 'Hindu - Kataria' },
  { key: 'hindu_khatri', label: 'Hindu - Khatri' },
  { key: 'hindu_koli', label: 'Hindu - Koli' },
  { key: 'hindu_korava', label: 'Hindu - Korava' },
  { key: 'hindu_kurmi', label: 'Hindu - Kurmi' },
  { key: 'hindu_kuruba', label: 'Hindu - Kuruba' },
  { key: 'hindu_mali', label: 'Hindu - Mali' },
  { key: 'hindu_maran', label: 'Hindu - Maran' },
  { key: 'hindu_mavani', label: 'Hindu - Mavani' },
  { key: 'hindu_meena', label: 'Hindu - Meena' },
  { key: 'hindu_mistry', label: 'Hindu - Mistry' },
  { key: 'hindu_modh', label: 'Hindu - Modh' },
  { key: 'hindu_muslim', label: 'Hindu - Muslim' },
  { key: 'hindu_nair', label: 'Hindu - Nair' },
  { key: 'hindu_nai', label: 'Hindu - Nai' },
  { key: 'hindu_rajput', label: 'Hindu - Rajput' },
  { key: 'hindu_ranjit', label: 'Hindu - Ranjit' },
  { key: 'hindu_sagara', label: 'Hindu - Sagara' },
  { key: 'hindu_sakya', label: 'Hindu - Sakya' },
  { key: 'hindu_sarawat', label: 'Hindu - Sarawat' },
  { key: 'hindu_saudagar', label: 'Hindu - Saudagar' },
  { key: 'hindu_sen', label: 'Hindu - Sen' },
  { key: 'hindu_sikh', label: 'Hindu - Sikh' },
  { key: 'hindu_sunni', label: 'Hindu - Sunni' },
  { key: 'hindu_thakur', label: 'Hindu - Thakur' },
  { key: 'hindu_tiwari', label: 'Hindu - Tiwari' },
  { key: 'hindu_yadav', label: 'Hindu - Yadav' },
  { key: 'islam_ahmadiyya', label: 'Islam - Ahmadiyya' },
  { key: 'islam_sunni', label: 'Islam - Sunni' },
  { key: 'islam_shia', label: 'Islam - Shia' },
  { key: 'sikh_sikh', label: 'Sikh - Sikh' },
  { key: 'buddhism_buddhist', label: 'Buddhism - Buddhist' },
];

const OPTIONS = {


  galleryAccessTypeOptions:{
    askToMe:"Ask Me",
    proMember:"Pro Member Only",
    all:"All Members",
    getAllOptions:()=>{
      return [
        OPTIONS.galleryAccessTypeOptions.all,
        OPTIONS.galleryAccessTypeOptions.proMember,
        OPTIONS.galleryAccessTypeOptions.askToMe,

      ]
    }
  },


  requestStatusOptions:{
    pending:"Pending",
    declined:"Declined",
    accepted:"Accepted",
    getAllOptions:()=>{
      return [
        OPTIONS.requestStatusOptions.pending,
        OPTIONS.requestStatusOptions.declined,
        OPTIONS.requestStatusOptions.accepted,

      ]
    }
  },


  reportProfileOptions: {
    allOptions: [
      'Fake and Incorrect Information',
      'Photo and Person Do Not Match',
      'Not Interested in Marrying',
      'Already Engaged/Married',
      'Inappropriate Language',
      'Unwanted Message and Call',
      'Asking for Money',
      'Other',
    ],

    getAllOption: () => {
      return OPTIONS.reportProfileOptions.allOptions;
    },
  },

  ageOptions: {
    allOptions: [
      { value: 18, label: '18' },
      { value: 19, label: '19' },
      { value: 20, label: '20' },
      { value: 21, label: '21' },
      { value: 22, label: '22' },
      { value: 23, label: '23' },
      { value: 24, label: '24' },
      { value: 25, label: '25' },
      { value: 26, label: '26' },
      { value: 27, label: '27' },
      { value: 28, label: '28' },
      { value: 29, label: '29' },
      { value: 30, label: '30' },
      { value: 31, label: '31' },
      { value: 32, label: '32' },
      { value: 33, label: '33' },
      { value: 34, label: '34' },
      { value: 35, label: '35' },
      { value: 36, label: '36' },
      { value: 37, label: '37' },
      { value: 38, label: '38' },
      { value: 39, label: '39' },
      { value: 40, label: '40' },
      { value: 41, label: '41' },
      { value: 42, label: '42' },
      { value: 43, label: '43' },
      { value: 44, label: '44' },
      { value: 45, label: '45' },
      { value: 46, label: '46' },
      { value: 47, label: '47' },
      { value: 48, label: '48' },
      { value: 49, label: '49' },
      { value: 50, label: '50' },
      { value: 51, label: '51' },
      { value: 52, label: '52' },
      { value: 53, label: '53' },
      { value: 54, label: '54' },
      { value: 55, label: '55' },
      { value: 56, label: '56' },
      { value: 57, label: '57' },
      { value: 58, label: '58' },
      { value: 59, label: '59' },
      { value: 60, label: '60' },
    ],
    getAllOptin: () => {
      return OPTIONS.ageOptions.allOptions.map((data) => data.value);
    },
  },
  familyTypeOptions: {
    nuclear: 'Nuclear Family',
    joint: 'Joint Family',
    getAllOptions: () => {
      return [
        OPTIONS.familyTypeOptions.nuclear,
        OPTIONS.familyTypeOptions.joint,
      ];
    },
  },

  familyStatusOptions: {
    rich: 'Rich/Affluent',
    uppderMiddleClass: 'Upper Middle Class',
    middleClass: 'Middle Class',
    getAllOptions: () => {
      return [
        OPTIONS.familyStatusOptions.rich,
        OPTIONS.familyStatusOptions.middleClass,
        OPTIONS.familyStatusOptions.middleClass,
      ];
    },
  },

  educationOptions: {
    highSchool: 'High school',
    associates: 'Associates',
    technicalSchool: 'Technical school',
    bachelors: 'Bachelors',
    masters: 'Masters',
    mbbs: 'MBBS',
    llb: 'LLB',
    jd: 'JD',
    md: 'MD',
    phd: 'PhD',
    caAcca: 'CA/ACCA',

    getAllOptions() {
      return [
        this.highSchool,
        this.associates,
        this.technicalSchool,
        this.bachelors,
        this.masters,
        this.mbbs,
        this.llb,
        this.jd,
        this.md,
        this.phd,
        this.caAcca,
      ];
    },
  },

  annualIncomeOptions: {
    getAllOption: [
      { value: -1, label: 'Not Specified' },
      { value: 0, label: 'Not Working' },
      { value: 0.5, label: 'Rs 0.5 - 1 Lakhs' },
      { value: 1, label: 'Rs 1 - 2 Lakhs' },
      { value: 2, label: 'Rs 2 - 3 Lakhs' },
      { value: 3, label: 'Rs 3 - 4 Lakhs' },
      { value: 5, label: 'Rs 5 - 7 Lakhs' },
      { value: 7, label: 'Rs 7 - 10 Lakhs' },
      { value: 10, label: 'Rs 10 - 12 Lakhs' },
      { value: 12, label: 'Rs 12 - 15 Lakhs' },
      { value: 15, label: 'Rs 15 - 20 Lakhs' },
      { value: 20, label: 'Rs 20 - 25 Lakhs' },
      { value: 25, label: 'Rs 25 - 30 Lakhs' },
      { value: 30, label: 'Rs 30 - 40 Lakhs' },
      { value: 40, label: 'Rs 40 - 50 Lakhs' },
      { value: 50, label: 'Rs 50 - 60 Lakhs' },
      { value: 60, label: 'Rs 60 - 70 Lakhs' },
      { value: 70, label: 'Rs 70 - 80 Lakhs' },
      { value: 80, label: 'Rs 80 - 90 Lakhs' },
      { value: 100, label: 'Rs 100 Lakhs and above' },
    ],

    getAllOptionAsArray: () => {
      return OPTIONS.annualIncomeOptions.getAllOption.map((data) => data.label);
    },

    getActualValue: (label) => {
      console.log('what label you got', label);
      return OPTIONS.annualIncomeOptions.getAllOption.filter(
        (data) => data.label == label
      )[0].value;
    },
  },

  maritalStatus: {
    neverMarried: 'Never Married',
    divorced: 'Divorced',
    separated: 'Separated',
    widowed: 'Widowed',

    getAllOptions() {
      return [this.neverMarried, this.divorced, this.separated, this.widowed];
    },
  },

  heightOptions: {
    allOptions: [
      { key: 4.6, label: '4 feet 6 inch' },
      { key: 4.7, label: '4 feet 7 inch' },
      { key: 4.8, label: '4 feet 8 inch' },
      { key: 4.9, label: '4 feet 9 inch' },
      { key: 4.1, label: '4 feet 10 inch' },
      { key: 4.11, label: '4 feet 11 inch' },
      { key: 5.0, label: '5 feet 0 inch' },
      { key: 5.1, label: '5 feet 1 inch' },
      { key: 5.2, label: '5 feet 2 inch' },
      { key: 5.3, label: '5 feet 3 inch' },
      { key: 5.4, label: '5 feet 4 inch' },
      { key: 5.5, label: '5 feet 5 inch' },
      { key: 5.6, label: '5 feet 6 inch' },
      { key: 5.7, label: '5 feet 7 inch' },
      { key: 5.8, label: '5 feet 8 inch' },
      { key: 5.9, label: '5 feet 9 inch' },
      { key: 5.1, label: '5 feet 10 inch' },
      { key: 5.11, label: '5 feet 11 inch' },
      { key: 6.0, label: '6 feet 0 inch' },
    ],

    // Method to get all key values in an array
    getAllOptions() {
      return OPTIONS.heightOptions.allOptions.map((data) => data.label);
    },
    getActualHeight(label) {
      console.log('height Checking', label);
      return OPTIONS.heightOptions.allOptions.filter(
        (data) => data.label == label
      )[0].key;
    },
  },

  higestEducationOptions: {
    ba: { key: 'B.A.', label: 'B.A.' },
    bed: { key: 'B.Ed', label: 'B.Ed' },
    bmc: {
      key: 'B.M.C. / B.M.M./ B.J.M.C.',
      label: 'B.M.C. / B.M.M./ B.J.M.C.',
    },
    bsc: { key: 'B.Sc.', label: 'B.Sc.' },
    bscBachelor: {
      key: 'B.Sc. - Bachelor of Science',
      label: 'B.Sc. - Bachelor of Science',
    },
    bfa: {
      key: 'Bachelor of Fine Arts - BFA / BVA',
      label: 'Bachelor of Fine Arts - BFA / BVA',
    },
    bachelorLibrary: {
      key: 'Bachelor of Library Science',
      label: 'Bachelor of Library Science',
    },
    bachelorPE: {
      key: 'Bachelor of Physical Education',
      label: 'Bachelor of Physical Education',
    },
    bachelorSocialWork: {
      key: 'Bachelor of Social Work',
      label: 'Bachelor of Social Work',
    },
    mmc: { key: 'M.M.C / M.M.M / M.J.M.C', label: 'M.M.C / M.M.M / M.J.M.C' },
    msc: { key: 'M.Sc.', label: 'M.Sc.' },
    mscAgri: { key: 'M.Sc. (Agriculture)', label: 'M.Sc. (Agriculture)' },
    ma: { key: 'Master of Arts - M.A.', label: 'Master of Arts - M.A.' },
    med: {
      key: 'Master of Education - M.Ed.',
      label: 'Master of Education - M.Ed.',
    },
    mfa: {
      key: 'Master of Fine Arts - MFA / MVA',
      label: 'Master of Fine Arts - MFA / MVA',
    },
    masterLibrary: {
      key: 'Master of Library Science',
      label: 'Master of Library Science',
    },
    masterPE: {
      key: 'Master of Physical Education',
      label: 'Master of Physical Education',
    },
    masterSocialWork: {
      key: 'Master of Social Work / M.A. Social Work',
      label: 'Master of Social Work / M.A. Social Work',
    },
    bca: { key: 'B.C.A.', label: 'B.C.A.' },
    bit: { key: 'B.IT', label: 'B.IT' },
    mca: { key: 'M.C.A.', label: 'M.C.A.' },
    phd: {
      key: 'Doctor of Philosophy - Ph.D.',
      label: 'Doctor of Philosophy - Ph.D.',
    },
    mphil: { key: 'M.Phil.', label: 'M.Phil.' },
    barch: { key: 'B.Arch', label: 'B.Arch' },
    bdes: { key: 'B.Des. / B.D.', label: 'B.Des. / B.D.' },
    bpharm: { key: 'B.Pharm / B.Pharma.', label: 'B.Pharm / B.Pharma.' },
    btech: { key: 'B.Tech / B.E.', label: 'B.Tech / B.E.' },
    march: { key: 'M.Arch.', label: 'M.Arch.' },
    mdes: { key: 'M.Des./ M.Design.', label: 'M.Des./ M.Design.' },
    mpharm: { key: 'M.Pharm', label: 'M.Pharm' },
    msEngg: { key: 'M.S. (Engineering)', label: 'M.S. (Engineering)' },
    mtech: { key: 'M.Tech / M.E', label: 'M.Tech / M.E' },
    bcom: { key: 'B.Com.', label: 'B.Com.' },
    cfa: { key: 'CFA', label: 'CFA' },
    ca: {
      key: 'Chartered Accountant - CA',
      label: 'Chartered Accountant - CA',
    },
    cs: { key: 'CS', label: 'CS' },
    icwa: { key: 'ICWA', label: 'ICWA' },
    mcom: { key: 'M.Com.', label: 'M.Com.' },
    aalimHafiz: {
      key: 'Aalim Hafiz / Alaima Hafiza',
      label: 'Aalim Hafiz / Alaima Hafiza',
    },
    llb: { key: 'Bachelor of Law - L.L.B.', label: 'Bachelor of Law - L.L.B.' },
    llm: { key: 'L.L.M.', label: 'L.L.M.' },
    bba: { key: 'B.B.A.', label: 'B.B.A.' },
    bhm: { key: 'BHM', label: 'BHM' },
    mba: { key: 'M.B.A.', label: 'M.B.A.' },
    bams: { key: 'B.A.M.S.', label: 'B.A.M.S.' },
    bds: { key: 'B.D.S.', label: 'B.D.S.' },
    bhms: { key: 'B.H.M.S', label: 'B.H.M.S' },
    bpt: { key: 'B.P.T.', label: 'B.P.T.' },
    bums: { key: 'B.U.M.S', label: 'B.U.M.S' },
    bachelorNursing: {
      key: 'Bachelor of Nursing',
      label: 'Bachelor of Nursing',
    },
    bvsc: { key: 'BVSc.', label: 'BVSc.' },
    dpharma: { key: 'D.Pharma', label: 'D.Pharma' },
    md: {
      key: 'Doctor of Medicine - M.D.',
      label: 'Doctor of Medicine - M.D.',
    },
    pharmD: {
      key: 'Doctor of Pharmacy - Pharm.D',
      label: 'Doctor of Pharmacy - Pharm.D',
    },
    dm: {
      key: 'Doctorate of Medicine - D.M.',
      label: 'Doctorate of Medicine - D.M.',
    },
    mbbs: { key: 'M.B.B.S.', label: 'M.B.B.S.' },
    mdHomoeopathy: { key: 'M.D. (Homoeopathy)', label: 'M.D. (Homoeopathy)' },
    mds: { key: 'M.D.S.', label: 'M.D.S.' },
    mpt: { key: 'M.P.T.', label: 'M.P.T.' },
    mvsc: { key: 'M.V.Sc.', label: 'M.V.Sc.' },
    mch: {
      key: 'Master of Chirurgiae - M.Ch.',
      label: 'Master of Chirurgiae - M.Ch.',
    },
    msSurgery: {
      key: 'Master of Surgery - M.S.',
      label: 'Master of Surgery - M.S.',
    },
    diploma: { key: 'Diploma', label: 'Diploma' },
    highSchool: { key: 'High School', label: 'High School' },
    intermediate: { key: 'Intermediate (12th)', label: 'Intermediate (12th)' },
    tradeSchool: { key: 'Trade School', label: 'Trade School' },
    other: { key: 'Other', label: 'Other' },

    // Method to get all key values in an array
    getAllOptionsAsobject() {
      return Object.values(this)
        .map((option) => option)
        .filter((e) => typeof e == 'object');
    },
    getAllOptions() {
      return Object.values(this)
        .map((option) => option.label)
        .filter((e) => e != undefined);
    },
  },

  fatherOccupation: {
    allOptions: [
      { key: 'employment_status', label: 'Business/Self Employed' },
      { key: 'employment_status', label: 'Private Sector' },
      { key: 'employment_status', label: 'Government/PSU Sector' },
      { key: 'employment_status', label: 'Defence' },
      { key: 'employment_status', label: 'Civil Services' },
      { key: 'employment_status', label: 'Politician' },
      { key: 'employment_status', label: 'Social Workers' },
      { key: 'employment_status', label: 'Not Working' },
      { key: 'employment_status', label: 'Retired' },
      { key: 'employment_status', label: 'Passed Away' },
    ],

    getAllOptions: () => {
      return OPTIONS.fatherOccupation.allOptions.map((data) => data.label);
    },
  },

  motherOcupation: {
    allOptions: [
      { key: 'employment_status', label: 'Homemaker/Housewife' },
      { key: 'employment_status', label: 'Business/Self Employed' },
      { key: 'employment_status', label: 'Private Sector' },
      { key: 'employment_status', label: 'Government/PSU Sector' },
      { key: 'employment_status', label: 'Defence' },
      { key: 'employment_status', label: 'Civil Services' },
      { key: 'employment_status', label: 'Politician' },
      { key: 'employment_status', label: 'Social Workers' },
      { key: 'employment_status', label: 'Not Working' },
      { key: 'employment_status', label: 'Retired' },
      { key: 'employment_status', label: 'Passed Away' },
    ],
    getAllOptions: () => {
      return OPTIONS.motherOcupation.allOptions.map((data) => data.label);
    },
  },

  employedInOptions: {
    employedinArray: [
      { key: 'Business / Self Employed', label: 'Business / Self Employed' },
      { key: 'Government / Public Sector', label: 'Government / Public Sector' },
      { key: 'Defence Sector', label: 'Defence Sector' },
      { key: 'Private Sector', label: 'Private Sector' },
      { key: 'not_working', label: 'Not Working' },
      { key: 'civil_service', label: 'Civil Service' },
    ],
    getAllOptions: function () {
      return this.employedinArray.map((e) => e.label);
    },
  },

  occupationOptions: {
    getAllOptions: function () {
      return OPTIONS.occupationOptionsArray.map((data) => data.label);
    },
    getFilterOptionsByEmployedInd: function (key) {
      return OPTIONS.occupationOptionsArray
        .filter((data) => data.key === key)
        .map((data) => data.label);
    },
  },

  motherTongueOptions: {
    assamese: { key: 'as', label: 'Assamese' },
    bengali: { key: 'bn', label: 'Bengali' },
    bhojpuri: { key: 'bh', label: 'Bhojpuri' },
    gujarati: { key: 'gu', label: 'Gujarati' },
    hindi: { key: 'hi', label: 'Hindi/Urdu' },
    kannada: { key: 'kn', label: 'Kannada' },
    kashmiri: { key: 'ks', label: 'Kashmiri' },
    malayalam: { key: 'ml', label: 'Malayalam' },
    marathi: { key: 'mr', label: 'Marathi' },
    nepali: { key: 'ne', label: 'Nepali' },
    odia: { key: 'or', label: 'Odia' },
    punjabi: { key: 'pa', label: 'Punjabi' },
    tamil: { key: 'ta', label: 'Tamil' },
    telugu: { key: 'te', label: 'Telugu' },
    urdu: { key: 'ur', label: 'Urdu' },
    sindhi: { key: 'sd', label: 'Sindhi' },
    maithili: { key: 'ma', label: 'Maithili' },
    sanskrit: { key: 'sa', label: 'Sanskrit' },
    konkani: { key: 'ko', label: 'Konkani' },
    manipuri: { key: 'mni', label: 'Manipuri' },
    dzongkha: { key: 'dz', label: 'Dzongkha' },
    santali: { key: 'sat', label: 'Santali' },
    bodo: { key: 'bodo', label: 'Bodo' },
    dogri: { key: 'dog', label: 'Dogri' },
    khasi: { key: 'kh', label: 'Khasi' },
    garo: { key: 'gar', label: 'Garo' },
    mundari: { key: 'munda', label: 'Mundari' },
    ho: { key: 'ho', label: 'Ho' },
    tulu: { key: 'tulu', label: 'Tulu' },
    lepcha: { key: 'lep', label: 'Lepcha' },
    limbu: { key: 'lim', label: 'Limbu' },
    rajasthani: { key: 'ra', label: 'Rajasthani' },
    tanchangya: { key: 'ta', label: 'Tanchangya' },

    // Method to get all key values in an array
    getAllOptions() {
      return [
        this.assamese.label,
        this.bengali.label,
        this.bhojpuri.label,
        this.gujarati.label,
        this.hindi.label,
        this.kannada.label,
        this.kashmiri.label,
        this.malayalam.label,
        this.marathi.label,
        this.nepali.label,
        this.odia.label,
        this.punjabi.label,
        this.tamil.label,
        this.telugu.label,
        this.urdu.label,
        this.sindhi.label,
        this.maithili.label,
        this.sanskrit.label,
        this.konkani.label,
        this.manipuri.label,
        this.dzongkha.label,
        this.santali.label,
        this.bodo.label,
        this.dogri.label,
        this.khasi.label,
        this.garo.label,
        this.mundari.label,
        this.ho.label,
        this.tulu.label,
        this.lepcha.label,
        this.limbu.label,
        this.rajasthani.label,
        this.tanchangya.label,
      ];
    },
  },

  accountCreatedFor: {
    self: 'Self',
    daughter: 'Daughter',
    son: 'Son',
    sister: 'Sister',
    brother: 'Brother',
    relative: 'Relative',
    friend: 'Friend',

    // Method to get all options sorted in ascending order
    getAllOptions() {
      return [
        this.self,
        this.daughter,
        this.son,
        this.sister,
        this.brother,
        this.relative,
        this.friend,
      ];
    },
  },

  genders: {
    MALE: 'MALE',
    FEMALE: 'FEMALE',

    getAllOptions: () => {
      return [OPTIONS.genders.MALE, OPTIONS.genders.FEMALE];
    },
  },

  religionOptions: {
    getAllOptions: () => OPTIONS.religionOptionsArray.map((item) => item.label),
  },

  usersRoles: {
    ADMIN: 'ADMIN',
    AREA_SALE_MANAGER: 'AREA_SALE_MANAGER',
    DEALER: 'DEALER',
    EMPLOYEE: 'EMPLOYEE',
    REGIONAL_SALES_MANAGER: 'REGIONAL_SALES_MANAGER',
    RETAILER: 'RETAILER',
    SALES_OFFICER: 'SALES_OFFICER',
    STOKIEST: 'STOKIEST',
    SUB_ADMIN: 'SUB_ADMIN',
    ZONAL_SALES_MANAGER: 'ZONAL_SALES_MANAGER',

    getAllRolesOfUserAsArray: function () {
      return [
        OPTIONS.usersRoles.ADMIN,
        OPTIONS.usersRoles.AREA_SALE_MANAGER,
        OPTIONS.usersRoles.DEALER,
        OPTIONS.usersRoles.EMPLOYEE,
        OPTIONS.usersRoles.REGIONAL_SALES_MANAGER,
        OPTIONS.usersRoles.SALES_OFFICER,
        OPTIONS.usersRoles.RETAILER,
        OPTIONS.usersRoles.STOKIEST,
        OPTIONS.usersRoles.SUB_ADMIN,
        OPTIONS.usersRoles.ZONAL_SALES_MANAGER,
      ];
    },
    getSUPER_ADMIN: () => [OPTIONS.usersRoles.ADMIN],
    getADMIN: () => [OPTIONS.usersRoles.ADMIN, OPTIONS.usersRoles.ADMIN],
    getEMPLOYEE: () => [
      OPTIONS.usersRoles.ADMIN,
      OPTIONS.usersRoles.SUB_ADMIN,
      OPTIONS.usersRoles.EMPLOYEE,
    ],
    getDEALER: () => [OPTIONS.usersRoles.DEALER],
    getRETAILER: () => [OPTIONS.usersRoles.RETAILER],
  },

  visitType: {
    NEW: 'NEW',
    COLLECTION: 'COLLECTION',
    ORDER: 'ORDER',
    getAllVisitAsArray: function () {
      return [
        OPTIONS.visitType.NEW,
        OPTIONS.visitType.COLLECTION,
        OPTIONS.visitType.ORDER,
      ];
    },
  },

  defaultStatusOFUser: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    DELETED: 'DELETED',
    BLACKLISTED: 'BLACKLISTED',
    getDefaultStatusOFUserasArray: function () {
      return [
        OPTIONS.defaultStatusOFUser.ACTIVE,
        OPTIONS.defaultStatusOFUser.INACTIVE,
        OPTIONS.defaultStatusOFUser.DELETED,
        OPTIONS.defaultStatusOFUser.BLACKLISTED,
      ];
    },

    getAllStatusOfUser: function () {
      return [
        OPTIONS.defaultStatusOFUser.ACTIVE,
        OPTIONS.defaultStatusOFUser.INACTIVE,
        OPTIONS.defaultStatusOFUser.BLACKLISTED,
      ];
    },
  },
  defaultOrderStatus: {
    ACTIVE: 'ACTIVE',
    ACCEPTED: 'ACCEPTED',
    DISPATCH: 'DISPATCH',
    DELIVERED: 'DELIVERED',
    CANCEL: 'CANCEL',
    RETURN: 'RETURN',
    PROCESSING: 'PROCESSING',
    REJECTED: 'REJECTED',
    getAllOrderStatusAsArray: function () {
      return [
        OPTIONS.defaultOrderStatus.DISPATCH,
        OPTIONS.defaultOrderStatus.ACTIVE,
        OPTIONS.defaultOrderStatus.DELIVERED,
        OPTIONS.defaultOrderStatus.CANCEL,
        OPTIONS.defaultOrderStatus.RETURN,
        OPTIONS.defaultOrderStatus.ACCEPTED,
        OPTIONS.defaultOrderStatus.REJECTED,
        OPTIONS.defaultOrderStatus.PROCESSING,
      ];
    },
  },

  defaultMeasureUnit: {
    KG: 'KG',
    GRAM: 'GRAM',
    LITER: 'LITER',
    ML: 'ML',
    PIECE: 'PIECE',
    METER: 'METER',
    getAllDefaultMeasureUnitasArray: function () {
      return [
        OPTIONS.defaultMeasureUnit.KG,
        OPTIONS.defaultMeasureUnit.GRAM,
        OPTIONS.defaultMeasureUnit.LITER,
        OPTIONS.defaultMeasureUnit.PIECE,
        OPTIONS.defaultMeasureUnit.METER,
        OPTIONS.defaultMeasureUnit.ML,
      ];
    },
  },

  defaultPaymentType: {
    CASH: 'CASH',
    CHECK: 'CHECK',
    UPI: 'UPI',
    getAllDdefaultPaymentTypeasArray: function () {
      return [
        OPTIONS.defaultPaymentType.CASH,
        OPTIONS.defaultPaymentType.CHECK,
        OPTIONS.defaultPaymentType.UPI,
      ];
    },
  },

  onlineStatus: {
    AVAILABLE: 'available',
    UN_AVAILABLE: 'un_available',
  },
  notificationTitle: {
    NEW_ORDER: `New Order !!`,
    NEW_ORDER_RECEIVED: `New order received !!`,
    STATUS_ORDER: (status) => `Order ${status} !!`,
    NEW_PRODUCT_REQUEST: `New product request !!`,
    STATUS_PRODUCT_REQUEST: 'Product request update !!',
    CHAT_MESSAGE: `New chat message !!`,
    OFFER_SHOP: `New deals & offer !!`,
    PLAN_EXPIRED: `Subscription plan expired !!`,
    PLAN_EXPIRED_WARNING: `Subscription plan expiry warning !!`,
    FEEDBACK: `New feedback received !!`,
    ENQUIRY: `New enquiry received !!`,
  },
  notificationMessage: {
    NEW_ORDER: (name, orderNumber) =>
      `Hey ${name}! Your order #${orderNumber} is successfully placed. View your order details here`,
    NEW_ORDER_RECEIVED: (name) =>
      `You have received a new order from ${name}. Check here`,
    STATUS_ORDER: (name, orderNumber, status) =>
      `Hey ${name}! Your order #${orderNumber} is ${status}. View your order details here`,
    DELIVERY_ORDER: (name, orderNumber, status) =>
      `Hey ${name}! Your order #${orderNumber} is ready. Please visit the shop to collect`,
    REJECTED_ORDER: (name, orderNumber) =>
      `Hey ${name}! Your order #${orderNumber} is cancelled. Check reason here and provide feedback here`,
    NEW_PRODUCT_REQUEST: (number) =>
      `You have received a new product request #${number}`,
    STATUS_PRODUCT_REQUEST: (status) =>
      `Your product request has been ${status}. Check the status here.`,
    CHAT_MESSAGE_SEND: (name) => `You have a message from ${name}.`,
    OFFER_SHOP: () => `Hey, there's a new offer. Please check deals & offer`,
    PLAN_EXPIRED: `Your subscription plan is expired. Please renew it to enjoy services`,
    PLAN_EXPIRED_IN_DAYS: (day) =>
      `Your subscription plan is going to expired in ${day} days. Please renew it to enjoy services`,
    PLAN_EXPIRED_TODAY: `Your subscription plan is going to expired today. Please renew it to enjoy services`,
    FEEDBACK: `You have received a new feedback. View feedback in admin panel`,
    ENQUIRY: `You have received a new enquiry. View enquiry in admin panel`,
    PRODUCT_REQUEST: `You have received a new product request. View product request in admin panel`,
  },
  notificationType: {
    ORDER: 'order',
    PRODUCT_REQUEST: 'product_request',
    CHAT_MESSAGE: `chat_message`,
    PROMOTION: 'promotion',
    SUBSCRIPTION_PLAN: `subscription_plan`,
    FEEDBACK: `feedback`,
    ENQUIRY: `enquiry`,
  },
  notificationType: {
    FOLLOW_USER: 'follow_user',
    NEW_POST: 'new_post',
  },

  religionOptionsArray: [
    { key: 'christianity', label: 'Christianity' },
    { key: 'hindu', label: 'Hindu' },
    { key: 'islam', label: 'Islam' },
    { key: 'sikh', label: 'Sikh' },
    { key: 'nonreligious', label: 'Nonreligious' },
    { key: 'bhori', label: 'Bhori' },
    { key: 'buddhism', label: 'Buddhism' },
    { key: 'christianity_anglican', label: 'Christianity - Anglican' },
    { key: 'christianity_baptist', label: 'Christianity - Baptist' },
    { key: 'christianity_born_again', label: 'Christianity - Born Again' },
    { key: 'christianity_catholic', label: 'Christianity - Catholic' },
    {
      key: 'christianity_catholic_kananaya',
      label: 'Christianity - Catholic Kananaya',
    },
    {
      key: 'christianity_catholic_latin',
      label: 'Christianity - Catholic Latin',
    },
    {
      key: 'christianity_catholic_malankara',
      label: 'Christianity - Catholic Malankara',
    },
    {
      key: 'christianity_catholic_roman',
      label: 'Christianity - Catholic Roman',
    },
    {
      key: 'christianity_catholic_syrian',
      label: 'Christianity - Catholic Syrian',
    },
    { key: 'christianity_cms', label: 'Christianity - CMS' },
    { key: 'christianity_csi', label: 'Christianity - CSI' },
    { key: 'christianity_evangelical', label: 'Christianity - Evangelical' },
    { key: 'christianity_jacobite', label: 'Christianity - Jacobite' },
    { key: 'christianity_malayalam', label: 'Christianity - Malayalam' },
    { key: 'christianity_marthomite', label: 'Christianity - Marthomite' },
    { key: 'christianity_nadar', label: 'Christianity - Nadar' },
    { key: 'christianity_orthodox', label: 'Christianity - Orthodox' },
    { key: 'christianity_pentecost', label: 'Christianity - Pentecost' },
    { key: 'christianity_protestant', label: 'Christianity - Protestant' },
    { key: 'christianity_thai', label: 'Christianity - Thai' },
    { key: 'christianity_tibetan', label: 'Christianity - Tibetan' },
    { key: 'dawoodi_bhora', label: 'Dawoodi Bhora' },
    { key: 'hindu_ganiga', label: 'Hindu - Ganiga' },
    { key: 'hindu_96k_kokanastha', label: 'Hindu - 96K Kokanastha' },
    { key: 'hindu_adi_dravida', label: 'Hindu - Adi Dravida' },
    { key: 'hindu_agamudayaar', label: 'Hindu - Agamudayaar' },
    { key: 'hindu_agarwal', label: 'Hindu - Agarwal' },
    { key: 'hindu_arora', label: 'Hindu - Arora' },
    { key: 'hindu_arya_vysya', label: 'Hindu - Arya Vysya' },
    { key: 'hindu_balija_naidu', label: 'Hindu - Balija Naidu' },
    { key: 'hindu_bania', label: 'Hindu - Bania' },
    { key: 'hindu_banjara', label: 'Hindu - Banjara' },
    { key: 'hindu_bengali', label: 'Hindu - Bengali' },
    { key: 'hindu_bhandari', label: 'Hindu - Bhandari' },
    { key: 'hindu_billava', label: 'Hindu - Billava' },
    { key: 'hindu_brahmin', label: 'Hindu - Brahmin' },
    { key: 'hindu_brahmin_anavil', label: 'Hindu - Brahmin Anavil' },
    { key: 'hindu_brahmin_bengali', label: 'Hindu - Brahmin Bengali' },
    { key: 'hindu_brahmin_davadnya', label: 'Hindu - Brahmin Davadnya' },
    { key: 'hindu_brahmin_deshastha', label: 'Hindu - Brahmin Deshastha' },
    { key: 'hindu_brahmin_garhwali', label: 'Hindu - Brahmin Garhwali' },
    { key: 'hindu_brahmin_gaur', label: 'Hindu - Brahmin Gaur' },
    {
      key: 'hindu_brahmin_gowd_saraswat',
      label: 'Hindu - Brahmin Gowd Saraswat',
    },
    { key: 'hindu_brahmin_gujarati', label: 'Hindu - Brahmin Gujarati' },
    { key: 'hindu_brahmin_havyaka', label: 'Hindu - Brahmin Havyaka' },
    {
      key: 'hindu_brahmin_kannada_madhva',
      label: 'Hindu - Brahmin Kannada Madhva',
    },
    { key: 'hindu_brahmin_kanyakubja', label: 'Hindu - Brahmin Kanyakubja' },
    { key: 'hindu_brahmin_kokanastha', label: 'Hindu - Brahmin Kokanastha' },
    { key: 'hindu_brahmin_kumoani', label: 'Hindu - Brahmin Kumaoni' },
    {
      key: 'hindu_brahmin_maharashtrian',
      label: 'Hindu - Brahmin Maharashtrian',
    },
    { key: 'hindu_brahmin_maithil', label: 'Hindu - Brahmin Maithil' },
    { key: 'hindu_brahmin_nagar', label: 'Hindu - Brahmin Nagar' },
    { key: 'hindu_brahmin_pareek', label: 'Hindu - Brahmin Pareek' },
    { key: 'hindu_brahmin_punjabi', label: 'Hindu - Brahmin Punjabi' },
    { key: 'hindu_brahmin_saryuparin', label: 'Hindu - Brahmin Saryuparin' },
    { key: 'hindu_brahmin_smartha', label: 'Hindu - Brahmin Smartha' },
    { key: 'hindu_brahmin_telugu', label: 'Hindu - Brahmin Telugu' },
    { key: 'hindu_bramhan_bhumihar', label: 'Hindu - Bramhan Bhumihar' },
    { key: 'hindu_bunt', label: 'Hindu - Bunt' },
    { key: 'hindu_byahut_kalwa', label: 'Hindu - Byahut Kalwa' },
    { key: 'hindu_chandraseniya_ckp', label: 'Hindu - Chandraseniya(CKP)' },
    { key: 'hindu_chettiar', label: 'Hindu - Chettiar' },
    { key: 'hindu_coorgi', label: 'Hindu - Coorgi' },
    { key: 'hindu_daraji', label: 'Hindu - Daraji' },
    { key: 'hindu_devadiga', label: 'Hindu - Devadiga' },
    { key: 'hindu_devanga', label: 'Hindu - Devanga' },
    { key: 'hindu_dheevara', label: 'Hindu - Dheevara' },
    { key: 'hindu_dhobi', label: 'Hindu - Dhobi' },
    { key: 'hindu_digambar', label: 'Hindu - Digambar' },
    { key: 'hindu_ezhava', label: 'Hindu - Ezhava' },
    { key: 'hindu_ezhuthassan', label: 'Hindu - Ezhuthassan' },
    { key: 'hindu_gold_smith', label: 'Hindu - Gold Smith' },
    { key: 'hindu_goswami', label: 'Hindu - Goswami' },
    { key: 'hindu_gounder', label: 'Hindu - Gounder' },
    { key: 'hindu_gowda', label: 'Hindu - Gowda' },
    { key: 'hindu_gujarati', label: 'Hindu - Gujarati' },
    { key: 'hindu_gupta', label: 'Hindu - Gupta' },
    { key: 'hindu_nair_veluthedath', label: 'Hindu - Hindu Nair Veluthedath' },
    { key: 'hindu_iyengar', label: 'Hindu - Iyengar' },
    { key: 'hindu_iyer', label: 'Hindu - Iyer' },
    { key: 'hindu_jat', label: 'Hindu - Jat' },
    { key: 'hindu_jain', label: 'Hindu - Jain' },
    { key: 'hindu_jatav', label: 'Hindu - Jatav' },
    { key: 'hindu_kachara', label: 'Hindu - Kachara' },
    { key: 'hindu_kadava', label: 'Hindu - Kadava' },
    { key: 'hindu_kannada', label: 'Hindu - Kannada' },
    { key: 'hindu_kapoor', label: 'Hindu - Kapoor' },
    { key: 'hindu_kataria', label: 'Hindu - Kataria' },
    { key: 'hindu_khatri', label: 'Hindu - Khatri' },
    { key: 'hindu_koli', label: 'Hindu - Koli' },
    { key: 'hindu_korava', label: 'Hindu - Korava' },
    { key: 'hindu_kurmi', label: 'Hindu - Kurmi' },
    { key: 'hindu_kuruba', label: 'Hindu - Kuruba' },
    { key: 'hindu_mali', label: 'Hindu - Mali' },
    { key: 'hindu_maran', label: 'Hindu - Maran' },
    { key: 'hindu_mavani', label: 'Hindu - Mavani' },
    { key: 'hindu_meena', label: 'Hindu - Meena' },
    { key: 'hindu_mistry', label: 'Hindu - Mistry' },
    { key: 'hindu_modh', label: 'Hindu - Modh' },
    { key: 'hindu_muslim', label: 'Hindu - Muslim' },
    { key: 'hindu_nair', label: 'Hindu - Nair' },
    { key: 'hindu_nai', label: 'Hindu - Nai' },
    { key: 'hindu_rajput', label: 'Hindu - Rajput' },
    { key: 'hindu_ranjit', label: 'Hindu - Ranjit' },
    { key: 'hindu_sagara', label: 'Hindu - Sagara' },
    { key: 'hindu_sakya', label: 'Hindu - Sakya' },
    { key: 'hindu_sarawat', label: 'Hindu - Sarawat' },
    { key: 'hindu_saudagar', label: 'Hindu - Saudagar' },
    { key: 'hindu_sen', label: 'Hindu - Sen' },
    { key: 'hindu_sikh', label: 'Hindu - Sikh' },
    { key: 'hindu_sunni', label: 'Hindu - Sunni' },
    { key: 'hindu_thakur', label: 'Hindu - Thakur' },
    { key: 'hindu_tiwari', label: 'Hindu - Tiwari' },
    { key: 'hindu_yadav', label: 'Hindu - Yadav' },
    { key: 'islam_ahmadiyya', label: 'Islam - Ahmadiyya' },
    { key: 'islam_sunni', label: 'Islam - Sunni' },
    { key: 'islam_shia', label: 'Islam - Shia' },
    { key: 'sikh_sikh', label: 'Sikh - Sikh' },
    { key: 'buddhism_buddhist', label: 'Buddhism - Buddhist' },
  ],
  occupationOptionsArray: [
    { key: 'Not Working', label: 'Not Working' },
    { key: 'not_Specified', label: 'Not Specified' },
    { key: 'Business / Self Employed', label: 'Actor/Model' },
    { key: 'Business / Self Employed', label: 'Advertising Professional' },
    { key: 'Business / Self Employed', label: 'Film/Entertainment Professional' },
    { key: 'Business / Self Employed', label: 'Media Professional' },
    { key: 'Business / Self Employed', label: 'Agriculture Professional' },
    { key: 'Business / Self Employed', label: 'Farming' },
    { key: 'Business / Self Employed', label: 'Architect' },
    { key: 'Business / Self Employed', label: 'Chartered Accountant' },
    { key: 'Business / Self Employed', label: 'Subject Matter Expert' },
    { key: 'Business / Self Employed', label: 'Dentist' },
    { key: 'Business / Self Employed', label: 'Doctor' },
    { key: 'Business / Self Employed', label: 'Surgeon' },
    { key: 'Business / Self Employed', label: 'Education Professional' },
    { key: 'Business / Self Employed', label: 'Educational Institution Owner' },
    { key: 'Business / Self Employed', label: 'Librarian' },
    { key: 'Business / Self Employed', label: 'Professor/Lecturer' },
    { key: 'Business / Self Employed', label: 'Research Assistant' },
    { key: 'Business / Self Employed', label: 'Teacher' },
    { key: 'Business / Self Employed', label: 'Hotels/Hospitality Professional' },
    { key: 'Business / Self Employed', label: 'Lawyer/Legal Professional' },
    { key: 'Business / Self Employed', label: 'Medical/Healthcare Professional' },
    { key: 'Business / Self Employed', label: 'Nurse' },
    { key: 'Business / Self Employed', label: 'Paramedic' },
    { key: 'Business / Self Employed', label: 'Pharmacist' },
    { key: 'Business / Self Employed', label: 'Physiotherapist' },
    { key: 'Business / Self Employed', label: 'Psychologist' },
    { key: 'Business / Self Employed', label: 'Veterinary Doctor' },
    { key: 'Business / Self Employed', label: 'Research Professional' },
    { key: 'Business / Self Employed', label: 'Science Professional' },
    { key: 'Business / Self Employed', label: 'Scientist' },
    { key: 'Business / Self Employed', label: 'Animator' },
    { key: 'Business / Self Employed', label: 'CxO/Chairman/President/Director' },
    { key: 'Business / Self Employed', label: 'VP/AVP/GM/DGM' },
    { key: 'Business / Self Employed', label: 'Agent' },
    { key: 'Business / Self Employed', label: 'Artist' },
    { key: 'Business / Self Employed', label: 'Beautician' },
    { key: 'Business / Self Employed', label: 'Broker' },
    { key: 'Business / Self Employed', label: 'Business Owner/Entrepreneur' },
    { key: 'Business / Self Employed', label: 'Businessperson' },
    { key: 'Business / Self Employed', label: 'Fashion Designer' },
    { key: 'Business / Self Employed', label: 'Fitness Professional' },
    { key: 'Business / Self Employed', label: 'Interior Designer' },
    { key: 'Business / Self Employed', label: 'Others' },
    { key: 'Business / Self Employed', label: 'Singer' },
    { key: 'Business / Self Employed', label: 'Social Services/NGO/Volunteer' },
    { key: 'Business / Self Employed', label: 'Sportsperson' },
    { key: 'Business / Self Employed', label: 'Travel Professional' },
    { key: 'Business / Self Employed', label: 'Writer' },
    { key: 'Business / Self Employed', label: 'Writer' },
    { key: 'civil_service', label: 'Civil Service' },
    { key: 'Defence Sector', label: 'Admin Professional' },
    { key: 'Defence Sector', label: 'Clerk' },
    { key: 'Defence Sector', label: 'Operator/Technician' },
    { key: 'Defence Sector', label: 'Airline Professional' },
    { key: 'Defence Sector', label: 'Pilot' },
    { key: 'Defence Sector', label: 'Air Force' },
    { key: 'Defence Sector', label: 'Army' },
    { key: 'Defence Sector', label: 'Defence Services' },
    { key: 'Defence Sector', label: 'Navy' },
    { key: 'Defence Sector', label: 'Dentist' },
    { key: 'Defence Sector', label: 'Doctor' },
    { key: 'Defence Sector', label: 'Surgeon' },
    { key: 'Defence Sector', label: 'Education Professional' },
    { key: 'Defence Sector', label: 'Educational Institution Owner' },
    { key: 'Defence Sector', label: 'Librarian' },
    { key: 'Defence Sector', label: 'Professor/Lecturer' },
    { key: 'Defence Sector', label: 'Research Assistant' },
    { key: 'Defence Sector', label: 'Teacher' },
    { key: 'Defence Sector', label: 'Electronics Engineer' },
    { key: 'Defence Sector', label: 'Engineer' },
    { key: 'Defence Sector', label: 'Hardware/Telecom Engineer' },
    { key: 'Defence Sector', label: 'Non – IT Engineer' },
    { key: 'Defence Sector', label: 'Quality Assurance Engineer' },
    { key: 'Defence Sector', label: 'Law Enforcement Officer' },
    { key: 'Defence Sector', label: 'Police' },
    { key: 'Defence Sector', label: 'Lawyer/Legal Professional' },
    { key: 'Defence Sector', label: 'Medical/Healthcare Professional' },
    { key: 'Defence Sector', label: 'Nurse' },
    { key: 'Defence Sector', label: 'Paramedic' },
    { key: 'Defence Sector', label: 'Pharmacist' },
    { key: 'Defence Sector', label: 'Physiotherapist' },
    { key: 'Defence Sector', label: 'Psychologist' },
    { key: 'Defence Sector', label: 'Veterinary Doctor' },
    { key: 'Defence Sector', label: 'Mariner' },
    { key: 'Defence Sector', label: 'Merchant Naval Officer' },
    { key: 'Defence Sector', label: 'Research Professional' },
    { key: 'Defence Sector', label: 'Science Professional' },
    { key: 'Government / Public Sector', label: 'Admin Professional' },
    { key: 'Government / Public Sector', label: 'Clerk' },
    { key: 'Government / Public Sector', label: 'Operator/Technician' },
    { key: 'Government / Public Sector', label: 'Secretary/Front Office' },
    { key: 'Government / Public Sector', label: 'Advertising Professional' },
    {
      key: 'Government / Public Sector',
      label: 'Film/Entertainment Professional',
    },
    { key: 'Government / Public Sector', label: 'Media Professional' },
    { key: 'Government / Public Sector', label: 'PR Professional' },
    { key: 'Government / Public Sector', label: 'Agriculture Professional' },
    { key: 'Government / Public Sector', label: 'Farming' },
    { key: 'Government / Public Sector', label: 'Airline Professional' },
    { key: 'Government / Public Sector', label: 'Flight Attendant' },
    { key: 'Government / Public Sector', label: 'Pilot' },
    { key: 'Government / Public Sector', label: 'Architect' },
    { key: 'Government / Public Sector', label: 'Chartered Accountant' },
    { key: 'Government / Public Sector', label: 'BPO/ITes Professional' },
    { key: 'Government / Public Sector', label: 'Customer Service' },
    { key: 'Government / Public Sector', label: 'Analyst' },
    { key: 'Government / Public Sector', label: 'Consultant' },
    { key: 'Government / Public Sector', label: 'Corporate Communication' },
    { key: 'Government / Public Sector', label: 'Corporate Planning' },
    { key: 'Government / Public Sector', label: 'HR Professional' },
    { key: 'Government / Public Sector', label: 'Marketing Professional' },
    { key: 'Government / Public Sector', label: 'Operations Management' },
    { key: 'Government / Public Sector', label: 'Product Manager' },
    { key: 'Government / Public Sector', label: 'Program Manager' },
    { key: 'Government / Public Sector', label: 'Project Manager - Non IT' },
    { key: 'Government / Public Sector', label: 'Sales Professional' },
    { key: 'Government / Public Sector', label: 'Sr. Manager/Manager' },
    { key: 'Government / Public Sector', label: 'Dentist' },
    { key: 'Government / Public Sector', label: 'Doctor' },
    { key: 'Government / Public Sector', label: 'Surgeon' },
    { key: 'Government / Public Sector', label: 'Education Professional' },
    { key: 'Government / Public Sector', label: 'Educational Institution Owner' },
    { key: 'Government / Public Sector', label: 'Librarian' },
    { key: 'Government / Public Sector', label: 'Professor/Lecturer' },
    { key: 'Government / Public Sector', label: 'Research Assistant' },
    { key: 'Government / Public Sector', label: 'Teacher' },
    { key: 'Government / Public Sector', label: 'Electronics Engineer' },
    { key: 'Government / Public Sector', label: 'Engineer' },
    { key: 'Government / Public Sector', label: 'Hardware/Telecom Engineer' },
    { key: 'Government / Public Sector', label: 'Non – IT Engineer' },
    { key: 'Government / Public Sector', label: 'Quality Assurance Engineer' },
    {
      key: 'Government / Public Sector',
      label: 'Hotels/Hospitality Professional',
    },
    { key: 'Government / Public Sector', label: 'Law Enforcement Officer' },
    { key: 'Government / Public Sector', label: 'Police' },
    { key: 'Government / Public Sector', label: 'Lawyer/Legal Professional' },
    {
      key: 'Government / Public Sector',
      label: 'Medical/Healthcare Professional',
    },
    { key: 'Government / Public Sector', label: 'Nurse' },
    { key: 'Government / Public Sector', label: 'Paramedic' },
    { key: 'Government / Public Sector', label: 'Pharmacist' },
    { key: 'Government / Public Sector', label: 'Physiotherapist' },
    { key: 'Government / Public Sector', label: 'Psychologist' },
    { key: 'Government / Public Sector', label: 'Veterinary Doctor' },
    { key: 'Government / Public Sector', label: 'Mariner' },
    { key: 'Government / Public Sector', label: 'Merchant Naval Officer' },
    { key: 'Government / Public Sector', label: 'Research Professional' },
    { key: 'Government / Public Sector', label: 'Science Professional' },
    { key: 'Government / Public Sector', label: 'Scientist' },
    { key: 'Government / Public Sector', label: 'Animator' },
    { key: 'Government / Public Sector', label: 'Cyber/Network Security' },
    { key: 'Government / Public Sector', label: 'Project Lead - IT' },
    { key: 'Government / Public Sector', label: 'Project Manager - IT' },
    {
      key: 'Government / Public Sector',
      label: 'Quality Assurance Engineer - IT',
    },
    { key: 'Government / Public Sector', label: 'Software Professional' },
    { key: 'Government / Public Sector', label: 'UI/UX Designer' },
    { key: 'Government / Public Sector', label: 'Web/Graphic Designer' },
    {
      key: 'Government / Public Sector',
      label: 'CxO/Chairman/President/Director',
    },
    { key: 'Government / Public Sector', label: 'VP/AVP/GM/DGM' },
    { key: 'Government / Public Sector', label: 'Agent' },
    { key: 'Government / Public Sector', label: 'Artist' },
    { key: 'Government / Public Sector', label: 'Broker' },
    { key: 'Government / Public Sector', label: 'Fitness Professional' },
    { key: 'Government / Public Sector', label: 'Interior Designer' },
    { key: 'Government / Public Sector', label: 'Others' },
    { key: 'Government / Public Sector', label: 'Politician' },
    { key: 'Government / Public Sector', label: 'Security Professional' },
    { key: 'Government / Public Sector', label: 'Social Services/NGO/Volunteer' },
    { key: 'Private Sector', label: 'Admin Professional' },
    { key: 'Private Sector', label: 'Clerk' },
    { key: 'Private Sector', label: 'Operator/Technician' },
    { key: 'Private Sector', label: 'Secretary/Front Office' },
    { key: 'Private Sector', label: 'Actor/Model' },
    { key: 'Private Sector', label: 'Advertising Professional' },
    { key: 'Private Sector', label: 'Film/ Entertainment Professional' },
    { key: 'Private Sector', label: 'Journalist' },
    { key: 'Private Sector', label: 'Media Professional' },
    { key: 'Private Sector', label: 'PR Professional' },
    { key: 'Private Sector', label: 'Agriculture Professional' },
    { key: 'Private Sector', label: 'Farming' },
    { key: 'Private Sector', label: 'Airline Professional' },
    { key: 'Private Sector', label: 'Flight Attendant' },
    { key: 'Private Sector', label: 'Pilot' },
    { key: 'Private Sector', label: 'Architect' },
    { key: 'Private Sector', label: 'Accounting Professional' },
    { key: 'Private Sector', label: 'Auditor' },
    { key: 'Private Sector', label: 'Banking Professional' },
    { key: 'Private Sector', label: 'Chartered Accountant' },
    { key: 'Private Sector', label: 'Finance Professional' },
    { key: 'Private Sector', label: 'BPO/ITes Professional' },
    { key: 'Private Sector', label: 'Customer Service' },
    { key: 'Private Sector', label: 'Analyst' },
    { key: 'Private Sector', label: 'Consultant' },
    { key: 'Private Sector', label: 'Corporate Communication' },
    { key: 'Private Sector', label: 'Corporate Planning' },
    { key: 'Private Sector', label: 'HR Professional' },
    { key: 'Private Sector', label: 'Marketing Professional' },
    { key: 'Private Sector', label: 'Operations Management' },
    { key: 'Private Sector', label: 'Product manager' },
    { key: 'Private Sector', label: 'Program Manager' },
    { key: 'Private Sector', label: 'Project Manager - Non IT' },
    { key: 'Private Sector', label: 'Sales Professional' },
    { key: 'Private Sector', label: 'Sr. Manager/ Manager' },
    { key: 'Private Sector', label: 'Subject Matter Expert' },
    { key: 'Private Sector', label: 'Dentist' },
    { key: 'Private Sector', label: 'Doctor' },
    { key: 'Private Sector', label: 'Surgeon' },
    { key: 'Private Sector', label: 'Education Professional' },
    { key: 'Private Sector', label: 'Educational Institution Owner' },
    { key: 'Private Sector', label: 'Librarian' },
    { key: 'Private Sector', label: 'Professor/Lecturer' },
    { key: 'Private Sector', label: 'Research Assistant' },
    { key: 'Private Sector', label: 'Teacher' },
    { key: 'Private Sector', label: 'Electronics Engineer' },
    { key: 'Private Sector', label: 'Engineer' },
    { key: 'Private Sector', label: 'Hardware/Telecom Engineer' },
    { key: 'Private Sector', label: 'Non – IT Engineer' },
    { key: 'Private Sector', label: 'Quality Assurance Engineer' },
    { key: 'Private Sector', label: 'Hotels/Hospitality Professional' },
    { key: 'Private Sector', label: 'Lawyer/ Legal Professional' },
    { key: 'Private Sector', label: 'Medical/ Healthcare Professional' },
    { key: 'Private Sector', label: 'Nurse' },
    { key: 'Private Sector', label: 'Paramedic' },
    { key: 'Private Sector', label: 'Pharmacist' },
    { key: 'Private Sector', label: 'Physiotherapist' },
    { key: 'Private Sector', label: 'Psychologist' },
    { key: 'Private Sector', label: 'Veterinary Doctor' },
    { key: 'Private Sector', label: 'Mariner' },
    { key: 'Private Sector', label: 'Merchant Naval Officer' },
    { key: 'Private Sector', label: 'Research Professional' },
    { key: 'Private Sector', label: 'Science Professional' },
    { key: 'Private Sector', label: 'Scientist' },
    { key: 'Private Sector', label: 'Animator' },
    { key: 'Private Sector', label: 'Cyber/Network Security' },
    { key: 'Private Sector', label: 'Project Lead - IT' },
    { key: 'Private Sector', label: 'Project Manager - IT' },
    { key: 'Private Sector', label: 'Quality Assurance Engineer - IT' },
    { key: 'Private Sector', label: 'Software Professional' },
    { key: 'Private Sector', label: 'UI/UX designer' },
    { key: 'Private Sector', label: 'Web/Graphic Designer' },
    { key: 'Private Sector', label: 'CxO/ Chairman/ President/ Director' },
    { key: 'Private Sector', label: 'VP/ AVP/ GM/ DGM' },
    { key: 'Private Sector', label: 'Agent' },
    { key: 'Private Sector', label: 'Artist' },
    { key: 'Private Sector', label: 'Beautician' },
    { key: 'Private Sector', label: 'Broker' },
    { key: 'Private Sector', label: 'Fashion Designer' },
    { key: 'Private Sector', label: 'Fitness Professional' },
    { key: 'Private Sector', label: 'Interior Designer' },
    { key: 'Private Sector', label: 'Others' },
    { key: 'Private Sector', label: 'Security Professional' },
    { key: 'Private Sector', label: 'Singer' },
    { key: 'Private Sector', label: 'Social Services/ NGO/ Volunteer' },
    { key: 'Private Sector', label: 'Sportsperson' },
    { key: 'Private Sector', label: 'Travel Professional' },

    { key: 'notWorking', label: 'Not  Working' },
  ],

  state: {
    allState: [
      {
        id: 4023,
        name: 'Not Specified',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'NS',
        type: 'Not Specified',
        latitude: '11.74008670',
        longitude: '92.65864010',
      },

      {
        id: 4023,
        name: 'Andaman and Nicobar Islands',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'AN',
        type: 'Union territory',
        latitude: '11.74008670',
        longitude: '92.65864010',
      },
      {
        id: 4017,
        name: 'Andhra Pradesh',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'AP',
        type: 'state',
        latitude: '15.91289980',
        longitude: '79.73998750',
      },
      {
        id: 4024,
        name: 'Arunachal Pradesh',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'AR',
        type: 'state',
        latitude: '28.21799940',
        longitude: '94.72775280',
      },
      {
        id: 4027,
        name: 'Assam',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'AS',
        type: 'state',
        latitude: '26.20060430',
        longitude: '92.93757390',
      },
      {
        id: 4037,
        name: 'Bihar',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'BR',
        type: 'state',
        latitude: '25.09607420',
        longitude: '85.31311940',
      },
      {
        id: 4031,
        name: 'Chandigarh',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'CH',
        type: 'Union territory',
        latitude: '30.73331480',
        longitude: '76.77941790',
      },
      {
        id: 4040,
        name: 'Chhattisgarh',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'CT',
        type: 'state',
        latitude: '21.27865670',
        longitude: '81.86614420',
      },
      {
        id: 4033,
        name: 'Dadra and Nagar Haveli and Daman and Diu',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'DH',
        type: 'Union territory',
        latitude: '20.39737360',
        longitude: '72.83279910',
      },
      {
        id: 4021,
        name: 'Delhi',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'DL',
        type: 'Union territory',
        latitude: '28.70405920',
        longitude: '77.10249020',
      },
      {
        id: 4009,
        name: 'Goa',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'GA',
        type: 'state',
        latitude: '15.29932650',
        longitude: '74.12399600',
      },
      {
        id: 4030,
        name: 'Gujarat',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'GJ',
        type: 'state',
        latitude: '22.25865200',
        longitude: '71.19238050',
      },
      {
        id: 4007,
        name: 'Haryana',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'HR',
        type: 'state',
        latitude: '29.05877570',
        longitude: '76.08560100',
      },
      {
        id: 4020,
        name: 'Himachal Pradesh',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'HP',
        type: 'state',
        latitude: '31.10482940',
        longitude: '77.17339010',
      },
      {
        id: 4029,
        name: 'Jammu and Kashmir',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'JK',
        type: 'Union territory',
        latitude: '33.27783900',
        longitude: '75.34121790',
      },
      {
        id: 4025,
        name: 'Jharkhand',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'JH',
        type: 'state',
        latitude: '23.61018080',
        longitude: '85.27993540',
      },
      {
        id: 4026,
        name: 'Karnataka',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'KA',
        type: 'state',
        latitude: '15.31727750',
        longitude: '75.71388840',
      },
      {
        id: 4028,
        name: 'Kerala',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'KL',
        type: 'state',
        latitude: '10.85051590',
        longitude: '76.27108330',
      },
      {
        id: 4852,
        name: 'Ladakh',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'LA',
        type: 'Union territory',
        latitude: '34.22684750',
        longitude: '77.56194190',
      },
      {
        id: 4019,
        name: 'Lakshadweep',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'LD',
        type: 'Union territory',
        latitude: '10.32802650',
        longitude: '72.78463360',
      },
      {
        id: 4039,
        name: 'Madhya Pradesh',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'MP',
        type: 'state',
        latitude: '22.97342290',
        longitude: '78.65689420',
      },
      {
        id: 4008,
        name: 'Maharashtra',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'MH',
        type: 'state',
        latitude: '19.75147980',
        longitude: '75.71388840',
      },
      {
        id: 4010,
        name: 'Manipur',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'MN',
        type: 'state',
        latitude: '24.66371730',
        longitude: '93.90626880',
      },
      {
        id: 4006,
        name: 'Meghalaya',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'ML',
        type: 'state',
        latitude: '25.46703080',
        longitude: '91.36621600',
      },
      {
        id: 4036,
        name: 'Mizoram',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'MZ',
        type: 'state',
        latitude: '23.16454300',
        longitude: '92.93757390',
      },
      {
        id: 4018,
        name: 'Nagaland',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'NL',
        type: 'state',
        latitude: '26.15843540',
        longitude: '94.56244260',
      },
      {
        id: 4013,
        name: 'Odisha',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'OR',
        type: 'state',
        latitude: '20.95166580',
        longitude: '85.09852360',
      },
      {
        id: 4011,
        name: 'Puducherry',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'PY',
        type: 'Union territory',
        latitude: '11.94159150',
        longitude: '79.80831330',
      },
      {
        id: 4015,
        name: 'Punjab',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'PB',
        type: 'state',
        latitude: '31.14713050',
        longitude: '75.34121790',
      },
      {
        id: 4014,
        name: 'Rajasthan',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'RJ',
        type: 'state',
        latitude: '27.02380360',
        longitude: '74.21793260',
      },
      {
        id: 4034,
        name: 'Sikkim',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'SK',
        type: 'state',
        latitude: '27.53297180',
        longitude: '88.51221780',
      },
      {
        id: 4035,
        name: 'Tamil Nadu',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'TN',
        type: 'state',
        latitude: '11.12712250',
        longitude: '78.65689420',
      },
      {
        id: 4012,
        name: 'Telangana',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'TG',
        type: 'state',
        latitude: '18.11243720',
        longitude: '79.01929970',
      },
      {
        id: 4038,
        name: 'Tripura',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'TR',
        type: 'state',
        latitude: '23.94084820',
        longitude: '91.98815270',
      },
      {
        id: 4022,
        name: 'Uttar Pradesh',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'UP',
        type: 'state',
        latitude: '26.84670880',
        longitude: '80.94615920',
      },
      {
        id: 4016,
        name: 'Uttarakhand',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'UK',
        type: 'state',
        latitude: '30.06675300',
        longitude: '79.01929970',
      },
      {
        id: 4853,
        name: 'West Bengal',
        country_id: 101,
        country_code: 'IN',
        country_name: 'India',
        state_code: 'WB',
        type: 'state',
        latitude: '22.98675690',
        longitude: '87.85497550',
      },
    ],

    getAllOptions: () => OPTIONS.state.allState.map((state) => state.name),
  },
};
module.exports = OPTIONS;

//  console.log("Your array", OPTIONS.religionOptions.getAllOptions())
console.log(
  'Your array',
  OPTIONS.heightOptions.getActualHeight('5 feet 3 inch')
);
