import React from 'react';
import ProfileCard from '../components/profileCard';
import { Grid, Box, Typography,Button } from '@mui/material';
import { useState,useEffect } from 'react';
import UserProfileService from '../../services/Profile.services';
const brideData = [


  {
    name: "Hina Bano",
    age: 27,
    height: "5ft 7in",
    maritalStatus: "Widowed",
    community: "Sunni / Siddiqui",
    education: "LLB",
    location: "Lucknow, India",
    imageUrl: "https://img.freepik.com/premium-photo/hyper-realistic-image-beautiful-25-year-old-hindu-indian-girl-with-traditional-dress_1123392-693.jpg"
  },
  {
    name: "Nadia Parveen",
    age: 24,
    height: "5ft 4in",
    maritalStatus: "Never Married",
    community: "Sunni / Qureshi",
    education: "MBA",
    location: "Chennai, India",
    imageUrl: "https://image.tensorartassets.com/cdn-cgi/image/anim=true,plain=false,w=2048,f=jpeg,q=85/posts/images/686428936309092459/865aab5c-e907-430f-a4b8-fc46d16dfb6b.jpg"
  },
  {
    name: "Amina Hussain",
    age: 23,
    height: "5ft 5in",
    maritalStatus: "Never Married",
    community: "Sunni / Syed",
    education: "BDS",
    location: "Pune, India",
    imageUrl: "https://i.pinimg.com/736x/ca/7d/5a/ca7d5aaa125f5cf99918ed25fefecf9c.jpg"
  },
  {
    name: "Sadia Rahman",
    age: 28,
    height: "5ft 3in",
    maritalStatus: "Divorced",
    community: "Sunni / Khan",
    education: "M.A in History",
    location: "Jaipur, India",
   imageUrl: "https://i.pinimg.com/736x/18/83/a2/1883a2faa195915363d58ddc1d3419ae.jpg"
  },
  {
    name: "Shifa Hayat",
    age: 22,
    height: "5ft 4in",
    maritalStatus: "Never Married",
    community: "Sunni / Ansari",
    education: "Doctor of Pharmacy - Pharm.D",
    location: "Maharashtra, India",
    imageUrl: "https://i.pinimg.com/736x/18/83/a2/1883a2faa195915363d58ddc1d3419ae.jpg"
  },
  {
    name: "Aisha Khan",
    age: 25,
    height: "5ft 5in",
    maritalStatus: "Never Married",
    community: "Sunni / Siddiqui",
    education: "MBBS",
    location: "Delhi, India",
    imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz-4LimNQAkUzIGElpQbmDKR8f0xEWNC2waQ&s"
  },
  {
    name: "Fatima Noor",
    age: 24,
    height: "5ft 3in",
    maritalStatus: "Never Married",
    community: "Sunni / Khan",
    education: "B.Tech in Computer Science",
    location: "Hyderabad, India",
    imageUrl: "https://cdn.openart.ai/published/4pSEhEglIwsiwhfTQmbU/1Ccmsfzl_PHdj_512.webp"
  },
  {
    name: "Zara Ahmed",
    age: 23,
    height: "5ft 6in",
    maritalStatus: "Divorced",
    community: "Sunni / Sheikh",
    education: "M.Sc in Physics",
    location: "Mumbai, India",
  imageUrl: "https://i.pinimg.com/736x/18/83/a2/1883a2faa195915363d58ddc1d3419ae.jpg"
  },
  
 
  {
    name: "Yasmin Shaikh",
    age: 21,
    height: "5ft 4in",
    maritalStatus: "Never Married",
    community: "Sunni / Sheikh",
    education: "Diploma in Fashion Designing",
    location: "Bangalore, India",
    imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-rX7KuNYEjhJBNtvCY-R2PJ2wfWHSMzT-Cw&s"
  },
  {
    name: "Rukaiya Javed",
    age: 29,
    height: "5ft 6in",
    maritalStatus: "Widowed",
    community: "Sunni / Ansari",
    education: "PhD in Biochemistry",
    location: "Agra, India",
    imageUrl: "https://images.pexels.com/photos/29390251/pexels-photo-29390251/free-photo-of-young-female-soccer-players-in-action-outdoors.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
  },
  {
    name: "Farah Naeem",
    age: 26,
    height: "5ft 5in",
    maritalStatus: "Never Married",
    community: "Sunni / Qureshi",
    education: "B.Sc in Microbiology",
    location: "Ahmedabad, India",
   imageUrl: "https://i.pinimg.com/736x/18/83/a2/1883a2faa195915363d58ddc1d3419ae.jpg"
  },
  {
    name: "Saira Mehmood",
    age: 24,
    height: "5ft 3in",
    maritalStatus: "Never Married",
    community: "Sunni / Pathan",
    education: "B.Com",
    location: "Bhopal, India",
    imageUrl: "https://cdn.openart.ai/published/nzoGqsvSI4xpVcnjXkJY/y7uBeIYg_1lDI_1024.webp"
  },
 
 
];

const ProfileView = () => {

  const[profile,setProfile]=useState([])

async function fetchData(){
    const data=await    UserProfileService.myIgnoreProfiles({});
    console.log("your data",data.data);
    setProfile(data.data)
}

useEffect(()=>{
fetchData();
},[])


  return (
    <Box  sx={{height:"100%",overflowY:'auto',p:2}}>
      <Typography variant="h6" color="initial" sx={{textAlign:"center",fontWeight:"bold"}}>{<Typography variant="body1" color="initial" sx={{fontWeight:"bold",boxShadow:2,display:"inline",padding:2,borderRadius:2}}> Profile Ignore by You</Typography>}</Typography>
      <Grid container spacing={2} sx={{my:2}}>
        {profile.map((item, index) => (
          <Grid 
            item 
            xs={12}  // 1 card per row on extra-small screens
            sm={6}   // 2 cards per row on small screens
            md={6}   // 3 cards per row on medium screens
            lg={6}   // 4 cards per row on large screens
            key={index}
            sx={{ px: { xs: 1, sm: 1, md: 1 }}}  // Side padding (left and right) on different breakpoints
          >
            <ProfileCard data={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProfileView;
