import apiRequest from "../../Component/customHool/axiosRequest";

const ProfileService = {
  async getMyProfile(params) {
    return apiRequest('GET', '/user/myProfile', {}, params);
  },

  async Create(payload) {
    return apiRequest('POST', '/Profile/create', payload, {});
  },

  async getById(id) {
    return apiRequest('GET', `/Profile/getById/${id}`, {}, {});
  },

  async updateMyFamilyInfo(payload, id) {
    return apiRequest('PUT', `/user/updatefamilyDetail`, payload, {});
  },

  async updateEducationAndCarrier(payload, id) {
    return apiRequest('PUT', `user/updateEducationAndCarrier`, payload, {});
  },
  async updatePartnerPreference(payload, id) {
    return apiRequest('PUT', `user/updatePartnerPreference`, payload, {});
  },

  async delete(id) {
    return apiRequest('DELETE', `/Profile/delete/${id}`, {}, {});
  }
};

export default ProfileService;