
import React, { useState } from 'react'


import {  Typography, Switch, Paper, FormControlLabel } from '@mui/material';
import { Box, TextField, Button, Link } from '@mui/material';

const PasswordAndNotification = () => {
  return (

    <Box sx={{height:"100%",overflowY:'auto'}}>


<Box
      sx={{
        maxWidth: 900,width:{xs:"95%"}, mx: 'auto', mt: 3, p: 3, borderRadius: 2, boxShadow: 3,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      
      <Typography variant="h5" sx={{ mb: 1 }}>
        Reset Password
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: 'gray' }}>
        Please choose your new password
      </Typography>
      <TextField
        label="Old Password"
        type="password"
        variant="outlined"
        fullWidth
      />
      <TextField
        label="New Password"
        type="password"
        variant="outlined"
        fullWidth
      />
      <TextField
        label="Confirm Password"
        type="password"
        variant="outlined"
        fullWidth
      />
      <Button
        variant="contained"
        color="success"
        fullWidth
        sx={{
          mt: 2,
         
        }}
      >
        Save New Password
      </Button>
    </Box>

    <Paper
      sx={{
        maxWidth: 900,width:{xs:"95%"}, mx: 'auto', mt: 3, p: 3, borderRadius: 2, boxShadow: 3
      }}
    >

<Typography variant="h5" sx={{ mb: 1 }}>
      Notification Settings
      </Typography>


      <NotificationSwitch
        title="What's App "
        description="Do you want to recieve the notification via What'App"
        defaultChecked={true}
      />
      <NotificationSwitch
        title="Email"
        description="Do you want to recieve the notification via Email"
        defaultChecked={false}
      />
      <NotificationSwitch
        title="SMS"
        description="Do you want to recieve the notification via SMS "
        defaultChecked={true}
      />

<Button
        variant="contained"
        color="success"
        fullWidth
        sx={{
          mt: 2,
          
        }}
      >
        Save Notification Settings
      </Button>
      
    </Paper>




    </Box>
  )
}

export default PasswordAndNotification


function NotificationSwitch({ title, description, defaultChecked }) {
  const [checked, setChecked] = useState(defaultChecked);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{title}</Typography>
        <Typography variant="body2" color="textSecondary">{description}</Typography>
      </Box>
      <Switch
        checked={checked}
        onChange={() => setChecked(!checked)}
        color="primary"
      />
    </Box>
  );
}