import React, { useState } from 'react';
import { Card, CardContent, Avatar, Typography, Grid, Box } from '@mui/material';
import {useNavigate,useSearchParams} from "react-router-dom"
const conversations = [
  { id: 1, name: 'Yaroslav Zubko', message: 'It is a long Ipsum available ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 2, name: 'Yaroslav Zubko', message: 'Grateful thoughts...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 3, name: 'Justas Galaburda', message: 'It is a long Ipsum available ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 4, name: 'Charles Patterson', message: 'OK, Thanks', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 5, name: 'Yaroslav Zubko', message: 'It is a long Ipsum available ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 6, name: 'Invision Inc', message: 'We own Hidden Lake ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 6, name: 'Invision Inc', message: 'We own Hidden Lake ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 6, name: 'Invision Inc', message: 'We own Hidden Lake ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 6, name: 'Invision Inc', message: 'We own Hidden Lake ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 6, name: 'Invision Inc', message: 'We own Hidden Lake ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 6, name: 'Invision Inc', message: 'We own Hidden Lake ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
  { id: 6, name: 'Invision Inc', message: 'We own Hidden Lake ...', avatar: 'https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180' },
];

const MessageSidebar = ({isSideBar,setIsSideBar,recentChat=[]}) => {
  const [activeTab, setActiveTab] = useState('all');
  const navigate=useNavigate()
  const [searchParams] = useSearchParams();


  const handleConverstaionClick=(userId)=>{

    const currentParams = Object.fromEntries(searchParams.entries());

    // Add or update query parameters
    const updatedParams = {
      ...currentParams,
      chatId: 12555,
      userId: userId,
    };

    // Convert the updated params object to a query string
    const queryString = new URLSearchParams(updatedParams).toString();

    // Navigate to the new URL with the updated query params
    navigate(`?${queryString}`);


  setIsSideBar(false)
  }

  return (
    <Box sx={{ width: "100%", bgcolor: 'background.paper', borderRight: '1px solid #e0e0e0', height: '100%',maxHeight:"100%",overflowY:'auto', p: 2 }}>
      {/* Tabs */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, borderBottom: '2px solid #e0e0e0',minWidth:"250px" ,overflow:"hidden"}}>
        <Typography
          variant="body1"
          sx={{
            cursor: 'pointer',
            borderBottom: activeTab === 'all' ? '2px solid #1976d2' : 'none',
            fontWeight: activeTab === 'all' ? 'bold' : 'normal',
          }}
          onClick={() => setActiveTab('all')}
        >
          All Conversations
        </Typography>
        <Typography
          variant="body1"
          sx={{
            cursor: 'pointer',
            borderBottom: activeTab === 'archived' ? '2px solid #1976d2' : 'none',
            fontWeight: activeTab === 'archived' ? 'bold' : 'normal',
          }}
          onClick={() => setActiveTab('archived')}
        >
          Archived
        </Typography>
      </Box>

      {/* Conversations List */}
      <Box>
        {recentChat.map((conversation) => (
          <Card key={conversation.id}  onClick={e=>handleConverstaionClick(conversation._id)} sx={{ mb: 1, boxShadow: 'none', borderRadius: 0,minWidth:"250px" ,cursor:"pointer",overflow:"hidden"}}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
              <Avatar src={conversation.profileImage} alt={conversation.firstName + " "+conversation.lastName} sx={{ mr: 2 }} />
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {conversation.firstName + " "+conversation.lastName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {conversation.lastName}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default MessageSidebar;
