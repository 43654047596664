import React from 'react';
import { FormControl, InputLabel, Select, MenuItem ,Autocomplete,TextField} from '@mui/material';
import { useState } from 'react';
export function ReusableSelect ({ label, name, value, options, onChange })  {
    console.log("option you got",options)
  return (
    <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value || ''}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


export function ReusableSelectWithKeyAndValue ({ label, name, value, options, onChange })  {
    console.log("option you got",options)
  return (
    <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
      <InputLabel>{label}</InputLabel>
      <Select
      fullWidth
        label={label}
        name={name}
        value={value || ''}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export function  ReusableMultipleSelect({ label, options, detail, setDetail, detailKey }) {
  // Internal state for this specific select
  const [selectedValues, setSelectedValues] = useState(detail[detailKey] || []);

  // Handle changes and update the parent `detail` state
  const handleSelectionChange = (newValue) => {
    setSelectedValues(newValue);

    // Update the corresponding key in the parent state
    setDetail((prevDetail) => ({
      ...prevDetail,
      [detailKey]: newValue,
    }));
  };

  return (
    <Autocomplete
      multiple
      fullWidth
      options={options}
      value={selectedValues}
      onChange={(event, newValue) => handleSelectionChange(newValue)}
      isOptionDisabled={(option) => selectedValues.includes(option)}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
    />
  )}
