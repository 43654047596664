import { Outlet } from 'react-router-dom'
import React from 'react'


const Payment = () => {
  return (
    <Outlet/>
  )
}

export default Payment