import React from 'react'
import MyPackageCard from '../components/profileCard/myPackageCard'
import NotificationSettingsCard from '../components/profileCard/notificationCard'
const Mypayment = () => {
  return (
    <div>

    <MyPackageCard/>
    </div>
  )
}

export default Mypayment