import { toast } from "react-toastify";
import axiosInstance from "../../utils/requestInstance";

const apiRequest = async (method, url, data = {}, params = {}) => {
  try {
    const response = await axiosInstance({
      method,
      url,
      data,
      params,
    });
    const result = response.data.result;
    if (result.message) toast.success(result.message); // Handle success toast
    return  result; // Return the relevant data
  } catch (error) {
    const errorMessage =
      error?.response?.data?.error?.message || // Nested error message
      error?.response?.data?.message ||        // Fallback in case it's less nested
      error?.message ||                        // General error message
      "Something went wrong!";                 // Default message if none are available

    toast.error(errorMessage);
    // Rethrow error so that calling functions can handle it if needed
  }
};

export default apiRequest
