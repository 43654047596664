import React, { useEffect } from 'react'
import PersonalDetailsCard from '../components/profileCard/personalCard'
import EducationCareerCard from '../components/profileCard/educationAndCarrierCard'
import FamilyInfoCard from '../components/profileCard/familyCards'
import PartnerPreferencesCard from '../components/profileCard/partnerPreferenceCard'
import MessageSidebar from '../components/profileCard/messageSideBar'
import RightMessage from '../components/profileCard/RightMessage'
import ResetPasswordCard from '../components/profileCard/passwordReset'
import UploadCard from '../components/profileCard/uploadsCard'
import { Height } from '@mui/icons-material'
import { Box } from '@mui/material'
import ProfileService from './profileService'
import { useDispatch } from 'react-redux'
import { updateUserProfile,setUserProfile } from '../../Component/redux/myProfileSlice'

const Profile = () => {
  
   async function  fetchuserProfile(){
   const data = await ProfileService.getMyProfile()
   console.log("data",data.data)
   dispatch(setUserProfile(data.data))
  }

  const dispatch=useDispatch()

  useEffect(()=>{
    fetchuserProfile();

  },[])
  return (
   <Box sx={{height:"100%",overflowY:'auto'}}>
<PersonalDetailsCard/>

<EducationCareerCard/>
    
  <FamilyInfoCard/>

  <PartnerPreferencesCard/>
   </Box>
  
  )
}

export default Profile