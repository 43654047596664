import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector } from "react-redux";
import { ReusableSelect } from "../../components/muiResuableComponents";
import OPTIONS from "../../../constant/Option";
import ProfileService from "../../Profile/profileService";
const EducationCareerCard = ({data={}}) => {
  const [isEditable, setIsEditable] = useState(false);

  const [details, setDetails] = useState({});
  useEffect(() => {
    setDetails(data);
  }, [data]);

  const handleEditToggle = () => {
    setIsEditable(!isEditable);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  
 async function updateDetails(){
  const data=await ProfileService.updateEducationAndCarrier(details,null)
 }

  return (
    <Card
      sx={{
        maxWidth: 900,
        width: { xs: "95%" },
        mx: "auto",
        mt: 3,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Education And Career
          </Typography>
         
        </Grid>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <ReusableSelect
                  label="Higest Education"
                  name="higestEducation"
                  value={details?.higestEducation}
                  options={OPTIONS?.higestEducationOptions.getAllOptions()}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Highest Education: {details?.higestEducation}
                </Typography>
              )}
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <ReusableSelect
                  label="Employed In"
                  name="employedIn"
                  value={details?.employedIn}
                  options={OPTIONS?.employedInOptions.getAllOptions()}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Employed In: {details?.employedIn}
                </Typography>
              )}
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable ? (
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Occupation Details"
                  variant="outlined"
                  name="occupationDetail"
                  value={details?.occupationDetail}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : (
                <Typography variant="body1">
                  {" "}
                  {isEditable ? null : (
                    <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
                  )}
                  Occupation Details: {details?.occupationDetail}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="College/Institute"
                  variant="outlined"
                  name="collegeInstitute"
                  value={details?.collegeInstitute}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : (
                <Typography variant="body1">
                  College/Institute: {details?.collegeInstitute}
                </Typography>
              )}
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <ReusableSelect
                  label="Occupation"
                  name="occupation"
                  value={details?.occupation}
                  options={OPTIONS?.occupationOptions.getFilterOptionsByEmployedInd(
                    details.employedIn
                  )}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Occupation: {details?.occupation}
                </Typography>
              )}
            </Grid>
            <Grid container alignItems="center" sx={{ mb: 1 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <ReusableSelect
                  label="Annual Income"
                  name="annualIncome"
                  value={details?.annualIncome}
                  options={OPTIONS?.annualIncomeOptions.getAllOptionAsArray()}
                  onChange={handleChange}
                />
              ) : (
                <Typography variant="body1">
                  Annual Income: {details?.annualIncome}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EducationCareerCard;
