import React from 'react'
import MyPackageCard from '../components/profileCard/myPackageCard'
const MYPackage
 = () => {
  return (
    <div>

      <MyPackageCard/>
        
    </div>
  )
}

export default MYPackage
