import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CircleIcon from "@mui/icons-material/Circle";
import { useSelector } from "react-redux";
import { ReusableSelect ,ReusableMultipleSelect} from "../../components/muiResuableComponents";

import OPTIONS from "../../../constant/Option";
import ProfileService from "../../Profile/profileService";

const PartnerPreferenceCard = ({data={}}) => {
  const [isEditable, setIsEditable] = useState(false);


  useEffect(() => {
   
    setDetails({...data,maritalStatus:[]});
  }, [data]);

  const [details, setDetails] = useState({});

  const handleEditToggle = () => {
    console.log("details",details)
    setIsEditable(!isEditable);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  async function updateDetails() {
    const data = await ProfileService.updatePartnerPreference(details, null);
  }

  return (
    <Card
      sx={{
        maxWidth: 900,
        width: { xs: "95%" },
        mx: "auto",
        mt: 3,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Partner Preference Information
          </Typography>
         
        </Grid>

        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" sx={{ mb: 2 }}>
             
              {isEditable ? (
                <ReusableMultipleSelect
                label="Marital Status"
                options={OPTIONS.maritalStatus.getAllOptions()}
                detail={details}
                setDetail={setDetails}
                detailKey="maritalStatus"
              />
              ) : (
<Typography variant="body1" sx={{display:"inline-block"}}>
  {isEditable ? null : (
    <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
  )}
  Marital Status: {<Typography variant="body2" color="initial" sx={{display:"inline-block"}}>{Array.isArray(details?.maritalStatus) 
    ? details.maritalStatus.join(', ') 
    : details?.maritalStatus}</Typography>}
</Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
             
              {isEditable ? (
               
                <ReusableMultipleSelect
                label="Higest Education"
                options={OPTIONS.higestEducationOptions.getAllOptions()}
                detail={details}
                setDetail={setDetails}
                detailKey="education"
              />
              ) : (
                <Typography variant="body1">
                  {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
                  Education: {details?.education}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
             
              {isEditable ? (
              
                <ReusableMultipleSelect
                label="Mother Tongue"
                options={OPTIONS.motherTongueOptions.getAllOptions()}
                detail={details}
                setDetail={setDetails}
                detailKey="motherTongue"
              />
              ) : (
                <Typography variant="body1">
                   {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
                  Mother Tongue: {details?.motherTongue}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
              {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
              {isEditable ? (
                <TextField
                  fullWidth
                  label="City"
                  variant="outlined"
                  name="city"
                  value={details?.city || ""}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                />
              ) : (
                <Typography variant="body1">
                  City: {details?.city}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" sx={{ mb: 2 }}>        
              {isEditable ? (
               <ReusableSelect
               label="Family Type"
               name="familyType"
               value={details?.annualIncome}
               options={OPTIONS?.annualIncomeOptions?.getAllOptionAsArray()}
               onChange={handleChange}
             />
              ) : (
                <Typography variant="body1">
                   {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
                  Annual Income: {details?.annualIncome}
                </Typography>
              )}
            </Grid>



            <Grid container alignItems="center" sx={{ mb: 2 }}>        
              {isEditable ? (
               <ReusableSelect
               label="Minimum height"
               name="heightFrom"
               value={details?.heightFrom}
               options={OPTIONS?.heightOptions.getAllOptions()}
               onChange={handleChange}
             />
              ) : (
                <Typography variant="body1">
                   {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
                Minimum Height: {details?.heightFrom}
                </Typography>
              )}
            </Grid>


            <Grid container alignItems="center" sx={{ mb: 2 }}>        
              {isEditable ? (
               <ReusableSelect
               label="Maximum height"
               name="heightTo"
               value={details?.heightTo}
               options={OPTIONS?.heightOptions.getAllOptions()}
               onChange={handleChange}
             />
              ) : (
                <Typography variant="body1">
                   {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
                Maximum Height: {details?.heightTo}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
             
              {isEditable ? (
                
                <ReusableMultipleSelect
                label="Occupation Types"
                options={OPTIONS.occupationOptions.getAllOptions()}
                detail={details}
                setDetail={setDetails}
                detailKey="occupation"
              />
              ) : (
                <Typography variant="body1">
                   {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
                  Occupation: {details?.occupation}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
             
              {isEditable ? (
                 <ReusableMultipleSelect
                 label="State"
                 options={OPTIONS.state.getAllOptions()}
                 detail={details}
                 setDetail={setDetails}
                 detailKey="state"
               />
              ) : (
                <Typography variant="body1">
                   {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
                  State: {details?.state}
                </Typography>
              )}
            </Grid>

            <Grid container alignItems="center" sx={{ mb: 2 }}>
             
              {isEditable ? (
                  <ReusableMultipleSelect
                  label="Country"
                  options={OPTIONS.state.getAllOptions()}
                  detail={details}
                  setDetail={setDetails}
                  detailKey="country"
                />
              ) : (
                <Typography variant="body1">
                   {isEditable ? null : (
                <CircleIcon sx={{ color: "red", fontSize: 8, mr: 1 }} />
              )}
                  Country: {details?.country}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PartnerPreferenceCard;
