import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { SettingsRemote } from '@mui/icons-material';
import { toast } from 'react-toastify';

const UploadCard = () => {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onDrop = (acceptedFiles) => {
   if(!files.length>=1){
    let eachFile={file:acceptedFiles[0],typeOfDocument:""}
    setFiles((prevFiles) => [...prevFiles,eachFile]);
   }else{
    toast.error("only on document is required")
   }
  };

  const handleDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.file.name !== fileName));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
const handlefileName=(value,index)=>{
console.log("your value")

}


useEffect(()=>{
    console.log("your file",files)
},[files])
  return (
    <Box
      sx={{
        maxWidth: isMobile ? '100%' : 700,
        margin: '0 auto',
        p: 2,
        borderRadius: 2,
        textAlign: 'center',
        mt: 3, p: 3, borderRadius: 2, boxShadow: 3 
      }}
    >
      <Typography variant="h6" gutterBottom>
      Upload your Id proof to get Verifeid
      </Typography>
      <Box
        {...getRootProps()}
        sx={{
          p: 3,
          border: isDragActive ? '2px solid #6a5acd' : '2px dashed #333333',
          borderRadius: 2,
          cursor: 'pointer',
          mb: 2,
          bgcolor: isDragActive ? '#f0f0ff' : '#f9f9f9',
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 48, color: '#6a5acd' }} />
        <Typography sx={{ mt: 1 }}>
          Drag & drop files or <span style={{ color: '#6a5acd', cursor: 'pointer' }}>Browse</span>
        </Typography>
        <Typography variant="caption">
          Supported formats: JPEG, PNG
        </Typography>
      </Box>

      {files.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ mb: 1 }}>Uploading - {files.length} file(s)</Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}

      <List>
        {files.map((file,index) => (
          <ListItem
            key={file.file.name}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: '1px solid #e0e0e0',
              borderRadius: 1,
              mb: 1,
              p: 1,
              bgcolor: '#f4f4f4',
            }}
          >
            <ListItemText primary={file.file.name} />
            <Select
              defaultValue=""
              sx={{ minWidth: 120, mr: 2 }}
              onChange={handlefileName(e=>e.target.value,index)}
            >
              <MenuItem value="aadhar">Aadhar Card</MenuItem>
              <MenuItem value="election">Election Card</MenuItem>
              <MenuItem value="passport">Passport</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            <IconButton edge="end" onClick={() => handleDelete(file.file.name)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>

      <Button
        variant="contained"
        color="success"
        fullWidth
        sx={{ mt: 2 }}
      >
        UPLOAD FILES
      </Button>
    </Box>
  );
};

export default UploadCard;
